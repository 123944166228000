import React, { useState, useContext } from "react";
import { UserContext } from "../../providers/UserContext";
import { signInFunc, signUpFunc, sendPasswordReset, getUserInfo } from "../../services/user";
import { db, dbfield } from "../../services/firebase";
import ReactLoading from "react-loading";
import { emailValidation } from "../../components/validation";
import { generateColor } from "../../components/color";
import { toast } from "react-toastify";

const EmailLogin = (props: any) => {
    const { setStatus, setIsAuth, setMenu, flag, setFlag, setUser, setAvator } =
        useContext(UserContext);
    const [email, setEmail] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirm, setConfirm] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    // const onclick_social_btn = () => {
    //     setStatus(1);
    // };

    const onclick_sign_up = () => {
        setFlag(1);
        setUsername("");
        setEmail("");
        setPassword("");
        setConfirm("");
    };

    // const onclick_login = () => {
    //     setFlag(0);
    //     setUser("");
    //     setEmail("");
    //     setPassword("");
    //     setConfirm("");
    // };

    const onclick_forgot_password = () => {
        setFlag(2);
        setUsername("");
        setEmail("");
        setPassword("");
        setConfirm("");
    };

    const onchange_input = (e: any) => {
        const type = e.target.name;
        const values = e.target.value;
        if (type === "email") {
            setEmail(values);
        } else if (type === "user") {
            if (!!values && values.length > 14) {
                toast.error(
                    <span className="toast-container">Please input the username less than 14 characters. </span>,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            } else setUsername(values);
        } else if (type === "password") {
            setPassword(values);
        } else {
            setConfirm(values);
        }
    };

    const onclick_login_btn = async () => {
        if (emailValidation(email) === null) {
            toast.error(
                <span className="toast-container">Email or password is incorrect</span>,
                {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
            return;
        }
        try {
            setLoading(true);
            const userCredential = await signInFunc(email, password);
            console.log('userSigninCredential', userCredential)
            if (userCredential.user?.emailVerified === true) {
                const UserCollection:any =  await getUserInfo();
                    if (!!UserCollection) {               
                    const users = {
                        isAuth: true,
                        userId: userCredential.user?.uid,
                        userinfo: UserCollection,
                    };
                    localStorage.setItem("user", JSON.stringify(users));
                    setIsAuth(true);
                    setStatus(0);
                    setUser(users.userId);
                    setAvator(UserCollection?.username)
                } 
            } else {
                toast.error(<span className="toast-container">Please verify your email</span>, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            toast.error(<span className="toast-container">Email or password is incorrect</span>, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const onclick_sendemail_btn = () => {
        sendPasswordReset(email)
            .then(() => {
                toast.success(
                    <span className="toast-container">
                        Please check your email for password reset instructions. You may need to check your spam folder.
                    </span>,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
                setFlag(0);
            })
            .catch((error) => {
                toast.error(<span className="toast-container">{error.message}</span>, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };

    const onclick_signup_btn = async () => {
        if (username === "") {
            toast.error(
                <span className="toast-container">Please enter a Username</span>,
                {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
            return;
        }
        if (email === "" || emailValidation(email) === null) {
            toast.error(
                <span className="toast-container">
                    Invalid or duplicate email address
                </span>,
                {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
            return;
        }

        if (password.length === 0 || confirm !== password) {
            toast.error(
                <span className="toast-container">
                    Please enter and confirm a password
                </span>,
                {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
            return;
        }
        try {
            setLoading(true);
            signUpFunc(email, password, username  ).then((res) => {
                //@ts-ignore
                if (res.status === 'ok') {
                    toast.info(<span className="toast-container">Please check your email for verification instructions. You may need to check your spam folder.</span>, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    setMenu(1);
                    setFlag(0);
                } else {
                    toast.error(<span className="toast-container">{res.message}</span>, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }).catch((error) => {
                toast.error(<span className="toast-container">Email address is already registered.</span>, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoading(false);
            })
            setLoading(false);
        } catch (error) {
            //@ts-ignore
            toast.error(<span className="toast-container">{error.message}</span>, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <div className="email_login">
                <div className="email-tabs">
                    {flag === 0 ? (
                        <div className="sign-container">
                            <span className="email_tab" onClick={onclick_sign_up}>
                                Sign up
                            </span>
                            <span
                                className="forgot-password"
                                onClick={onclick_forgot_password}
                            >
                                Forgot password
                            </span>
                        </div>
                    ) : (
                        <div className="sign-container"></div>
                    )}
                </div>
                {flag === 0 ? (
                    <div className="email_form">
                        <input
                            placeholder="Email"
                            type="email"
                            name="email"
                            onChange={(e) => onchange_input(e)}
                            value={email}
                            className="input-field"
                        />
                        <input
                            placeholder="Password"
                            type="password"
                            name="password"
                            onChange={(e) => onchange_input(e)}
                            value={password}
                            className="input-field"
                        />
                        <div className="email-btn" onClick={onclick_login_btn}>
                            {loading ? (
                                <ReactLoading
                                    type="spin"
                                    width={24}
                                    height={24}
                                    color="#9d9d9d"
                                ></ReactLoading>
                            ) : (
                                "Sign In"
                            )}
                        </div>
                    </div>
                ) : flag === 1 ? (
                    <div className="email_form">
                        <input
                            placeholder="Username"
                            type="text"
                            name="user"
                            onChange={(e) => onchange_input(e)}
                            value={username}
                            className="input-field"
                        />
                        <input
                            placeholder="Email"
                            type="email"
                            name="email"
                            onChange={(e) => onchange_input(e)}
                            value={email}
                            className="input-field"
                        />
                        <input
                            placeholder="Password"
                            type="password"
                            name="password"
                            onChange={(e) => onchange_input(e)}
                            value={password}
                            className="input-field"
                        />
                        <input
                            placeholder="Confirm"
                            type="password"
                            name="confirm"
                            onChange={(e) => onchange_input(e)}
                            value={confirm}
                            className="input-field"
                        />
                        <div className="email-btn" onClick={onclick_signup_btn}>
                            {loading ? (
                                <ReactLoading
                                    type="spin"
                                    width={24}
                                    height={24}
                                    color="#9d9d9d"
                                ></ReactLoading>
                            ) : (
                                "Sign Up"
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="email_form">
                        <input
                            placeholder="Email"
                            type="email"
                            name="email"
                            onChange={(e) => onchange_input(e)}
                            value={email}
                            className="input-field"
                        />
                        <div className="email-btn" onClick={onclick_sendemail_btn}>
                            Send Reset Instructions
                        </div>
                    </div>
                )}
            </div>
            <div className="policy">
                <span>
                    By continuing, you agree to the <span className="terms" onClick={() => window.open("https://trivia-client-terms.netlify.app/", "_self")}> Terms </span> and <span className="terms" onClick={() => window.open("https://trivia-client-privacy.netlify.app/", "_self")}> Privacy Polcy.</span>
                </span>
            </div>
        </React.Fragment>
    );
};

export default EmailLogin;
