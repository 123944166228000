import React, { useState, useEffect } from "react";
// import Header from "../layout/Header";
import { useNavigate } from "react-router-dom";
import GameRuleImage from "../../assets/icons/game-rule.png";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const GameRule = () => {
  const naviagte = useNavigate();
  const [screenHeight, setScreenHeight] = useState<number | null>();
  const { height } = useWindowDimensions();

  useEffect(() => {
    setScreenHeight(height);
  }, []);

  return (
    <React.Fragment>
      <div
        className="game-rule"
        // style={
        //   screenHeight
        //     ? {
        //         height: "100vh",
        //       }
        //     : {
        //         height: screenHeight + "px",
        //       }
        // }
      >
        <div className="header">
          <span className="menu_btn" onClick={() => naviagte("/")}>
            Back
          </span>
        </div>
        <div className="game-rule-header">
          <img
            src={GameRuleImage}
            alt="game-rule"
            className="game_rule_image"
          />
          <h1 className="game-rule-title">Game Rules</h1>
          <div className="game-rule-content">
            <p>
              1. Players must be registered and signed in, before the the
              countdown reaches zero, to participate in the live game.
            </p>
            <p>
              2. Players answer 12 timed questions by selecting one of four text or 
              image-only answer choices and are awarded one point for each
              correct answer. Highest score wins.
            </p>
            <p>
              3. Players receive one 50-50 lifeline per game,
              which is activated by tapping the heart shaped icon. The lifeline
              will remove two incorrect answer choices.
            </p>
            <p>
              4. In case of a tie, the leader with the fastest average response
              time is the winner.
            </p>
            <p>
              5. As live games are synced, players with slow devices or
              connections may be unable to complete the game. The same applies
              to players who exit and return to the app while the game is in
              progress.
            </p>
            <p> Good Luck!</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GameRule;
