import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../providers/UserContext";
import trophyImg from "../../assets/icons/trophy.png";
import mooseImg from "../../assets/icons/moose.png";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import classNames from 'classnames';

const HighScore = () => {
    const navigate = useNavigate();
    const { allRank, userInfo, setMenu } = useContext(UserContext);
    const [screenHeight, setScreenHeight] = useState<number | null>();
    const [firstPlaceEmail , setFirstPlaceEmail] = useState<string>('');
    const { height } = useWindowDimensions();

    useEffect(() => {
        setMenu(2);
        setScreenHeight(height);    
        if (!allRank) navigate('/');
        if (allRank && allRank.length > 0) {
          let liveRank = allRank.filter((item:any )=> (!item?.prev_playtime) || (item.play_time === item?.prev_playtime))
          if (liveRank.length > 0) setFirstPlaceEmail(liveRank[0].email);
          else setFirstPlaceEmail(allRank[0].email);
        }
    }, []);

    return (
        <div
            className="relative w-full overflow-hidden px-6"
            style={{
                height: screenHeight ? screenHeight + "px" :  "100vh" ,
                backgroundColor : '#1c1331'
            }}
        >
            <div className='absolute left-6 top-7  text-xs cursor-pointer'
                style={{fontFamily : 'LargeFont', color : '#5652b8' }}
                onClick={()=>navigate('/result')}
            >
                BACK
            </div>
            <div className="w-full h-full py-12 ">
                <div className="flex justify-center w-4/12 m-auto pb-1">
                    <img className='w-8/12 h-8/12' src={mooseImg} />
                </div>
                <div className="text-md text-white text-center pt-3" style={{fontFamily : 'LargeFont'}}>
                    HIGH SCORES
                </div>
                <div className=' h-5/6 overflow-auto'>
                    <table className="w-full mt-4 relative" style={{fontFamily : 'LargeFont', fontSize : '12px', color : '#5652b8'}} >
                        <thead className=''>
                            <tr className="text-white">
                                <th className='p-0 pt-2 pb-2 w-0.5'>no</th>
                                <th className='px-3.5 pt-2 pb-2 w-full'>player</th>
                                <th  className='p-0 pt-2 pb-2 text-right w-1' colSpan={2}>score</th>
                            </tr>
                        </thead>
                        <tbody className='mt-5'>
                            {!!allRank && allRank.map((item: any, key: number) => {
                                if (item) {
                                            return (
                                                <tr className={classNames(userInfo.email === item.email ? "text-white": (!item?.prev_playtime) || (item.play_time === item?.prev_playtime) ? '' : '')} 
                                                    key={key} >
                                                    <td className="text-right p-0 pt-2 pb-2 ">{key + 1}</td>
                                                    <td className='flex px-3.5 pt-2 pb-2 '>
                                                        <div className="relative">
                                                            {item.username}
                                                            { item.email === firstPlaceEmail && (
                                                            <img className='absolute left-full top-2/4 ml-4' 
                                                                style={{transform : 'translateY(-50%)'}}
                                                                src={trophyImg} alt="trophyImg" width="25px" 
                                                                />)}
                                                        </div>
                                                    </td>
                                                    <td className="p-0 pt-2 pb-2">{item.score}</td>
                                                    <td className="text-sm p-0 pt-2 pb-2 text-right" style={{fontFamily : 'small-font'}}>{item.time.toFixed(1)}</td>
                                                </tr>
                                            );
                                    } 
                            })}
                            {!allRank && [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16].map((v, i) => (
                             //for test                            
                                <tr className={classNames(i=== 5 ? 'text-teal-200' : '')} key={i}>
                                    <td className="text-right p-0 pt-2 pb-2 ">{v}</td>
                                    <td className='flex px-3.5 pt-2 pb-2 '>
                                        <div className="relative">
                                            abcijklmn {v}
                                            { i === 0 && <img className='absolute left-full top-2/4 ml-4' 
                                                style={{transform : 'translateY(-50%)'}}
                                                src={trophyImg} alt="trophyImg" width="25px" /> 
                                            }
                                        </div>
                                    </td>
                                    <td className="p-0 pt-2 pb-2 ">{v+2}</td>
                                    <td className="text-sm p-0 pt-2 pb-2 text-right" style={{fontFamily : 'small-font'}}>9.5</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default HighScore;
