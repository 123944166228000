import "hacktimer/HackTimer.js";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../providers/UserContext";
import { useNavigate } from "react-router-dom";
import readyImage from "../../assets/icons/image.png";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useSound from "use-sound";
import axios from "axios";
import backgroundImg from "../../assets/icons/background-clear.png";
import smallBackgroundImg from "../../assets/icons/background-clear-small.png";
import backgroundCloudImg from "../../assets/icons/background.png";
import smallBackgroundCloudImg from "../../assets/icons/background-small.png";
import foregroundWithoutMooseImg from "../../assets/icons/foreground-game.png";
import mooseImg from "../../assets/icons/moose.png";
import correctImg from "../../assets/icons/correctImg.png";
import incorrectImg from "../../assets/icons/incorrectImg.png";
import lifelineImg from "../../assets/icons/lifeline.png";
import helicopterImg from "../../assets/icons/get-ready-banner.png";
import classNames from 'classnames';
import ImgOrText, { getColor } from "../../components/ImgOrText";
import styles from './styles.module.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // import css for blur effect

const ORIGINAL_READYTIME = 13;
const ORIGINAL_COUNTDOWNTIME = 10;
const ORIGINAL_DELAYFORANSWERTIME = 7;
const ORIGINAL_RESULTSHOWTIME = 8;

const LivegameGuest = () => {
    const navigate = useNavigate();
    const {
        questionlist,
        questionImageList,
        setScore,
        setMenu,
        ready,
        setReady,
        averageTime,
        setAverageTime,
        prev_game_num,
        prev_startTime,
        isLive,
        setIsLive,
        user,
        score,
        userInfo,
        setResult,
        questionStatus,
        soundPlay,
        setSoundPlay,
        setFlag,
        setStatus,
        setTabStatus,
        setIsSpeed,
        setQuestionImageList,
        setQuestionList,
        setQuestionStatus,
        setRank,
        setAllRank,
        setSync
    } = useContext(UserContext);
    const [prev_playTime, setPrevPlayTime] = useState<number>(0);
    const [num, setNum] = useState<number>(0);
    const [countdown, setCountDown] = useState<number>(10);
    const [resultShowTimeCounter, setResultShowTimeCounter] =
        useState<number>(8);
    const [isFinish, setIsFinish] = useState<boolean>(false);
    const [screenStatus, setScreenStatus] = useState<boolean>(false);
    const [selected, setSelected] = useState<number>(-1);
    const [selectedTime, setSelectedTime] = useState<number>(10);
    const [isCorrect, setIsCorrect] = useState<number>(2);
    const [btnclick, setBtnClick] = useState<boolean>(false);
    const [showcorrectanswer, setShowCorrectAnswer] = useState<boolean>(false);
    const [lifebtn, setLifeBtn] = useState<boolean>(true);  //true
    const [lifebtnClicked, setLifeBtnClicked] = useState<boolean>(false);  //true
    const [currentTime, setCurrentTime] = useState<Date>(new Date());
    const [avTime, setAvTime] = useState<any>(0);
    const [delayForAnswer, setDelayForAnswer] = useState<number>(7);
    const [percent, setPercent] = useState<any>();
    const [imageNumbers, setImageNumbers] = useState<Array<number>>([3, 2, 1, 0]);
    const [life, setLife] = useState<boolean>(false);
    const [isSync, setIsSync] = useState<boolean>(false);
    const [timeup, setTimeUp] = useState<boolean>(false);
    const [screenHeight, setScreenHeight] = useState<number | null>();
    const [turnon, setTurnOn] = useState<boolean>(false);
    const { height } = useWindowDimensions();
    const [btn_50, setBtn_50] = useState<boolean>(false);
    const [correctPlay, setCorrectPlay] = useState<boolean>(false);
    const [incorrectPlay, setIncorrectPlay] = useState<boolean>(false);
    const [isPending, setIsPending] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [play] = useSound("./countdown.mp3");
    const [play_50] = useSound("./50-50.mp3", { volume: 0.6 });
    const [play_correct] = useSound("./correct.wav");
    const [play_incorrect] = useSound("./incorrect.wav");
    const [play_button] = useSound("./buttons.wav");
    const [play_endgame] = useSound("./end_game.mp3");

    const [questionsNum, setQuestionsNum] = useState(12);
    const [questionStatus1, setQuestionStatus1] = useState([]);
    const [questionImage1, setQuestionImage1] = useState([]);
    const [flagQ, setFlagQ] = useState(0);
    const [flagClick, setFlagClick] = useState(0);
    const [flagGetAnswer, setFlagGetAnswer] = useState(0);
    const [timediffCS, setTimediffCS] = useState<number>(0);

    const getQuestion = async (questionNum: number) => {
        const res = await axios.get(`/guest/question/${questionNum}`);
        return res.data;
    }
    const getAnswer = async (questionNum: number, selected: number, selectedTime: number) => {
        const res = await axios.post(`/guest/answer`, {
            questionNum, selected, selectedTime
        });
        return res.data;
    }

    useEffect(() => {
        getServerClientTimediff().then(timediff => {
          setTimediffCS(timediff);
          setPrevPlayTime(Date.now() - timediff ); //start time for prev game.
        })
        if (!prev_game_num || prev_game_num < 0) {  //  && imageUrlList === undefined
            navigate("/");
            setMenu(0);
            setIsSpeed(false);
            setTabStatus(false);
            setIsLive(false);
        }
        if (isPending === false) {
            setAverageTime(0);
            setResult(false);
            setScore(0);
            setReady(10);
        }
        setAvTime(0);
        setScreenHeight(height);
        setSoundPlay(false);
    }, []);

    useEffect(() => {
        if (turnon === true) {
            if (soundPlay === true) {
                if (visible) {
                    play();
                    setVisible(false);
                }
            }
            if (btn_50 === true) {
                play_50();
                setBtn_50(false);
            }
            if (correctPlay) {
                play_correct();
                setCorrectPlay(false);
            }
            if (incorrectPlay) {
                play_incorrect();
                setIncorrectPlay(false);
            }
        }
    }, [soundPlay, btn_50, correctPlay, incorrectPlay]);

    useEffect(() => {
        const temp = [...imageNumbers];
        if (ready % 4 === 0) {
            temp[3] = ready;
            setImageNumbers(temp);
        }
        if (ready % 4 === 1) {
            temp[2] = ready;
            setImageNumbers(temp);
        }
        if (ready % 4 === 2) {
            temp[1] = ready;
            setImageNumbers(temp);
        }
        if (ready % 4 === 3) {
            temp[0] = ready;
            setImageNumbers(temp);
        }
        if (ready == 2) get_question();
        if (ready <= 0) {
            setIsSync(true);
        }
    }, [ready]);

    useEffect(() => {
        if (isPending === false) {
            let prevTime = Date.now();
            const temp = setInterval(() => {
                let t1 = Date.now();
                if (t1 - prevTime >= 1000) {
                    play_50();
                    setReady((prev) => prev - 1);
                    // console.log("ready", Date.now(), prevTime)
                }
            }, 1000);
            if (ready <= 0) {
                setVisible(true);
                setReady(0);
                clearInterval(temp);
                let st = getServertime()
                setTimeout(() => {
                    setIsPending(true);
                    setCurrentTime(new Date());
                    setCountDown(10);
                    setSoundPlay(true);
                }, ORIGINAL_READYTIME * 1000 - (st - prev_playTime));
            }
            return () => {
                clearInterval(temp);
            };
        }
    }, [isSync]);

    useEffect(() => {
        if (window.navigator.onLine) {
            if (isPending === true) {
                // console.log(num,'countdown', countdown, ' time ',  Date.now()/1000)
                let prevTime = Date.now();
                const temp = setInterval(() => {
                    let t1 = Date.now();
                    if (t1 - prevTime >= 1000) {
                        setCountDown((prev) => prev - 1);
                    }
                }, 100);

                if (flagClick > 0 && countdown == 1) {  //after updating the question
                    setFlagGetAnswer(1);       //console.log(num, "answer1", Date.now(), countdown, flagClick)
                    getAnswer(num, selected, selectedTime).then(ans => {
                        questionStatus[num].info.correct = ans.data?.info?.correct;
                        questionStatus[num].info.incorrect = ans.data?.info?.incorrect;
                        questionlist[num].info.correct = ans.data?.info?.correct;
                        questionlist[num].info.incorrect = ans.data?.info?.incorrect;
                        questionlist[num].answer = ans.data?.answer;
                        questionlist[num].percent = ans.data?.percent;
                        get_percent(ans.data);
                        question_result(selected, ans.data);
                    })
                }
                if (countdown <= 0) {
                    setSoundPlay(false);
                    setVisible(false);
                    setCountDown(0);
                    clearInterval(temp);
                    setLife(false);
                    setLifeBtn(false);

                    if (selected < 0 && flagClick == 0 && flagGetAnswer == 0) {
                        setFlagGetAnswer(1);          //console.log(num, 'answer2', Date.now(), countdown )
                        getAnswer(num, selected, selectedTime).then(ans => {
                            questionStatus[num].info.correct = ans.data?.info?.correct;
                            questionStatus[num].info.incorrect = ans.data?.info?.incorrect;
                            questionlist[num].info.correct = ans.data?.info?.correct;
                            questionlist[num].info.incorrect = ans.data?.info?.incorrect;
                            questionlist[num].answer = ans.data?.answer;
                            questionlist[num].percent = ans.data?.percent;
                            get_percent(ans.data);
                            question_result(selected, ans.data);
                        })
                    }
                    let st = getServertime();
                    let t = st / 1000 - (prev_playTime / 1000 + ORIGINAL_READYTIME) - ORIGINAL_COUNTDOWNTIME - (ORIGINAL_COUNTDOWNTIME + ORIGINAL_DELAYFORANSWERTIME + ORIGINAL_RESULTSHOWTIME) * num;
                    setIsFinish(true);
                    setDelayForAnswer(7 - t);

                    setTimeUp(true);
                }
                return () => {
                    clearInterval(temp);
                };
            }

        } else {
            navigate("/");
            setMenu(0);
            setSoundPlay(false);
            setStatus(0);
            setFlag(0);
            setIsSpeed(true);
        }
    }, [isPending, countdown]); 

    useEffect(() => {
        if (selected >= 0 && flagClick == 1 && flagGetAnswer == 0 && countdown <= 1) {  //after countdown = 0
            setFlagGetAnswer(1);       //console.log(num, "answer3", Date.now, selected, flagClick,countdown)
            getAnswer(num, selected, selectedTime).then(ans => {
                questionStatus[num].info.correct = ans.data?.info?.correct;
                questionStatus[num].info.incorrect = ans.data?.info?.incorrect;
                questionlist[num].info.correct = ans.data?.info?.correct;
                questionlist[num].info.incorrect = ans.data?.info?.incorrect;
                questionlist[num].answer = ans.data?.answer;
                questionlist[num].percent = ans.data?.percent;
                get_percent(ans.data);
                question_result(selected, ans.data);
            })
        }
    }, [flagClick]);

    useEffect(() => {
        if (isFinish === true) {
            //console.log(num,'delayforanswer', delayForAnswer, ' time ',  Date.now()/1000)
            let prevTime = Date.now()
            const temp = setInterval(() => {
                let t1 = Date.now()
                if (t1 - prevTime >= 1000) {
                    setDelayForAnswer((prev) => prev - 1);
                }
            }, 100);

            if (btnclick === false) {
                let t = 10;
                let bb = ((averageTime * num + 10) / (num + 1)).toFixed(3);
                questionStatus[num].selectTime = t.toFixed(3);
                setAvTime(bb);
                console.log('avtime', bb);
            }
            setBtnClick(true);
            setLife(false);

            if (delayForAnswer <= 0) {
                setAverageTime(avTime);
                setResultShowTimeCounter(8);
                setShowCorrectAnswer(true);
                if (isCorrect === 1) {
                    setCorrectPlay(true);
                    setScore(score + 1); console.log(num, "score, time", isCorrect, score + 1, avTime)
                } else {
                    setIncorrectPlay(true); console.log(num, "score, time", isCorrect, score, avTime)
                }
                if (num + 1 === questionsNum) {
                    get_endgameTime();
                }
                setScreenStatus(true);
                setTimeUp(false);
                clearInterval(temp);
                
                getQuestion(num).then(ques => {
                    questionlist[num].answer = ques.data?.answer;
                    questionlist[num].percent = ques.data?.percent;
                    get_percent(ques.data);
                })
            }

            return () => {
                clearInterval(temp);
            };
        }
    }, [isFinish, delayForAnswer]);

    useEffect(() => {
        if (showcorrectanswer === true) {
            // console.log(num,'resultshowTime', resultShowTimeCounter, ' time ',  Date.now()/1000)
            let prevTime = Date.now();
            const temp = setInterval(() => {
                let t1 = Date.now()
                if (t1 - prevTime >= 1000) {
                    setResultShowTimeCounter((prev) => prev - 1);
                }
            }, 100);

            var ifConnected = window.navigator.onLine;
            if (resultShowTimeCounter == 2 && ifConnected && num + 1 < questionsNum) {
                let t1 = Date.now();
                getQuestion(num + 1).then(res => {   //console.log(num, "question", Date.now(), resultShowTimeCounter )
                    let question = res.data;  //object
                    let question1: any = {
                        answer: [
                            { answer: question.answer[0].answer, percent: question.answer[0].percent, url: question.answer[0].url, url_nft: question.answer[0].url_nft },
                            { answer: question.answer[1].answer, percent: question.answer[1].percent, url: question.answer[1].url, url_nft: question.answer[1].url_nft },
                            { answer: question.answer[2].answer, percent: question.answer[2].percent, url: question.answer[2].url, url_nft: question.answer[2].url_nft },
                            { answer: question.answer[3].answer, percent: question.answer[3].percent, url: question.answer[3].url, url_nft: question.answer[3].url_nft }
                        ],
                        info: {
                            correct: question.info.correct,
                            incorrect: question.info.incorrect,
                            question: question.info.question
                        },
                        percent: question.percent,
                        difficulty: question.difficulty
                    };
                    setQuestionList([...questionlist, { ...question1 }]);

                    let question2: any = {
                        answer: [
                            { percent: question.answer[0].percent, url: question.answer[0].url, url_nft: question.answer[0].url_nft },
                            { percent: question.answer[1].percent, url: question.answer[1].url, url_nft: question.answer[1].url_nft },
                            { percent: question.answer[2].percent, url: question.answer[2].url, url_nft: question.answer[2].url_nft },
                            { percent: question.answer[3].percent, url: question.answer[3].url, url_nft: question.answer[3].url_nft }
                        ],
                        info: {
                            correct: question.info.correct,
                            incorrect: question.info.incorrect,
                            question: question.info.question
                        },
                        percent: question.percent,
                        difficulty: question.difficulty
                    };
                    setQuestionStatus1(question2);  //current object 
                    setQuestionStatus([...questionStatus, { ...question2, selectTime: 0 }]);
                    // console.log("questionStatus", [...questionStatus, {...question, selectTime:0}])
                    let questionImage = question.answer.map((item: any, key: number) => ({
                        ...item,
                        id: key
                    })); //
                    setQuestionImage1(questionImage);
                    setQuestionImageList([...questionImageList, questionImage]);
                    setFlagQ(1);
                    let t2 = Date.now();  //console.log("question", num+1, t2-t1, res.data)
                    if (t2 - t1 >= 2000) {
                        setNum((prev) => prev + 1);
                        setCountDown(10);
                        setSoundPlay(true);
                        setCurrentTime(new Date());
                        setFlagQ(0);
                    }
                })
            }
            if (resultShowTimeCounter <= 0) {
                if (ifConnected) {
                    setResultShowTimeCounter(0);
                    if (!lifebtnClicked) setLifeBtn(true);
                    if (num + 1 < questionsNum) {
                        setScreenStatus(false);
                        setIsFinish(false);
                        setSelected(-1);
                        setSelectedTime(10);
                        setSoundPlay(true);
                        setVisible(true);
                        setIsCorrect(2);
                        setBtnClick(false);
                        setShowCorrectAnswer(false);
                        if (flagQ > 0) {
                            setNum((prev) => prev + 1);
                            setCountDown(10);
                            setSoundPlay(true);
                            setCurrentTime(new Date());
                            setFlagQ(0);
                        }
                        setFlagGetAnswer(0);
                        setFlagClick(0)  //for button click

                        clearInterval(temp);
                    } else if (num + 1 === questionsNum) {
                        if (turnon) play_endgame();
                        navigate("/result");
                        clearInterval(temp);
                    }

                } else {
                    navigate("/");
                    setMenu(0);
                    setSoundPlay(false);
                    setStatus(0);
                    setFlag(0);
                    setIsSpeed(true);
                    setReady(10);
                }
            }

            return () => {
                clearInterval(temp);
            }
        }
    }, [resultShowTimeCounter, showcorrectanswer]);

    const get_percent = (answer: any) => {
        setPercent(answer);
    };

    const get_endgameTime = async () => {
        setSync(true);
        setTimeout(() => get_Data(), 2000);  //get the rankings.
    }

    const onclick_lifeline = () => {
        if (!btnclick && lifebtn && !lifebtnClicked) {
            axios.post(`/guest/answer/lifeline/${num}`).then((res) => {
                let ans: any = res.data;
                console.log("lifeline", ans.data, questionStatus[num])
                questionStatus[num].info.correct = ans?.data.info.correct;
                questionStatus[num].info.incorrect = ans?.data.info.incorrect;
                setBtn_50(true);
                setLifeBtn(false);
                setLifeBtnClicked(true);
                setLife(true);
            })
        }
    };

    const onclick_soundbtn = () => {
        play_correct();
        setTurnOn(true);
    };

    const question_result = (selected1: number, ans: any) => {
        if (selected1 === ans?.info?.correct) {
            setIsCorrect(1);
            questionImageList[num][selected1].isCorrect = true;
        } else {
            setIsCorrect(0);
        }  //console.log(num, "question result",selected1, ans )
    };

    const onclick_question_image = async (item: any, key: number) => {
        play_button();
        let getTime = new Date();
        questionImageList[num][key].isSelected = true;
        questionImageList[num][key].percent += 1;
        questionlist[num].percent += 1;
        questionlist[num].answer[item.id].percent += 1;
        questionStatus[num].percent += 1;
        questionStatus[num].answer[item.id].percent += 1;
        questionStatus[num].answer[item.id].isSelected = true;
        let aa = ((getTime.getTime() - currentTime.getTime()) / 1000).toFixed(3);
        setSelected(item.id);
        setSelectedTime(parseFloat(aa));
        //@ts-ignore
        let bb = ((averageTime * num + Number(aa)) / (num + 1)).toFixed(3);
        setAvTime(bb);
        console.log('avtime', bb);
        questionStatus[num].selectTime = aa;
        setBtnClick(true);
        //console.log("click-countdown, selected", countdown,item.id);
        await axios.post(`/guest/question/${num}/update`, { q_item: item.id })
        setFlagClick(1);  //end the updating.
    };
    const getServerClientTimediff = async () => {
        let t1 = Date.now();
        const res: any = await axios.get('/server-time');
        let t2 = Date.now();
        return t2 - (res.data.data + (t2 - t1) / 2);
    }
    const getServertime = () => {
        return Date.now() - timediffCS;
    }

    const get_question = async () => {
        setScore(0);
        let res = await getQuestion(0);
        let question = res.data;  //object  
        // console.log("queston1", question);
        let question1: any = {
            answer: [
                { answer: question.answer[0].answer, percent: question.answer[0].percent, url: question.answer[0].url, url_nft: question.answer[0].url_nft },
                { answer: question.answer[1].answer, percent: question.answer[1].percent, url: question.answer[1].url, url_nft: question.answer[1].url_nft },
                { answer: question.answer[2].answer, percent: question.answer[2].percent, url: question.answer[2].url, url_nft: question.answer[2].url_nft },
                { answer: question.answer[3].answer, percent: question.answer[3].percent, url: question.answer[3].url, url_nft: question.answer[3].url_nft }
            ],
            info: {
                correct: question.info.correct,
                incorrect: question.info.incorrect,
                question: question.info.question
            },
            percent: question.percent,
            difficulty: question.difficulty
        };
        setQuestionList([{ ...question1 }]);

        let question2: any = {
            answer: [
                { percent: question.answer[0].percent, url: question.answer[0].url, url_nft: question.answer[0].url_nft },
                { percent: question.answer[1].percent, url: question.answer[1].url, url_nft: question.answer[1].url_nft },
                { percent: question.answer[2].percent, url: question.answer[2].url, url_nft: question.answer[2].url_nft },
                { percent: question.answer[3].percent, url: question.answer[3].url, url_nft: question.answer[3].url_nft }
            ],
            info: {
                correct: question.info.correct,
                incorrect: question.info.incorrect,
                question: question.info.question
            },
            percent: question.percent,
            difficulty: question.difficulty,
            selectTime: 0
        };
        setQuestionStatus1(question2);  //current object 
        setQuestionStatus([{ ...question2, selectTime: 0 }]);

        let questionImage = question.answer.map((item: any, key: number) => ({
            ...item,
            id: key
        })); //
        setQuestionImage1(questionImage);
        setQuestionImageList([questionImage]);
    }

    const get_Data = async () => {
        if (isLive) {
            const resp: any = await axios.get('/guest/finish-game')
            const allrankingTemp = resp.data.data;
            const info = resp.data.info;
            setRank(info.rank)
            setScore(info.score);
            setAvTime((parseFloat(info.time)).toFixed(3));
            setAllRank(allrankingTemp);
            setResult(true);
        }
    };

    return (
        <div
            className="relative w-full overflow-hidden"
            style={{
                height: screenHeight ? screenHeight + "px" : "100vh",
                padding: '0 50px',
            }}
        >
            {isPending === true ? (   //original - true
                <>
                    <div className='absolute w-full h-full top-0 left-0' style={{ zIndex: '-1' }}>
                        <LazyLoadImage
                            className=""
                            alt='background'
                            src={backgroundImg}
                            placeholderSrc={smallBackgroundImg}
                            // effect="blur"
                            delayTime={1}
                        />
                    </div>
                    <div className='absolute w-full h-full left-0'>
                        <div className='absolute bottom-0 w-full'
                            style={{ height: '52vw', maxHeight: '240px' }}
                        >
                            <img src={foregroundWithoutMooseImg} alt='foreground'
                                className='absolute bottom-0 w-full' style={{ zIndex: '-1' }} />
                            {screenStatus === false ? ( //original-false
                                <>
                                    <img src={mooseImg} alt="bottom Image"
                                        className='absolute left-12 pb-1' style={{ width: '18%', bottom: '22%' }} />
                                    <div className='w-full h-full flex  justify-center items-center'>
                                        <div className='text-6xl text-center px-8 relative' style={{ fontFamily: 'LargeFont', color: '#409c78', paddingBottom: '5%' }}>
                                            <div style={{ paddingLeft: 'calc(0.14 * 1em)' }}>{countdown}</div>
                                            <div className={classNames("absolute left-0 text-xl w-full text-center", countdown === 0 ? '' : 'opacity-0')}
                                                style={{ fontFamily: 'small-font', color: '#409c78', transform: 'tranlateX(-50%)', bottom: '-10px' }}>
                                                TIME'S UP!
                                            </div>
                                        </div>
                                    </div>
                                    <img src={lifelineImg} alt='lifeline' className={classNames("absolute bottom-1/4 mb-10 right-10 w-1/12 object-contain", lifebtnClicked ? 'opacity-0' : '')}
                                        onClick={onclick_lifeline}
                                    />
                                </>
                            ) : (
                                <>
                                    <div className="absolute left-12 pb-1" style={{ width: '18%', bottom: '22%' }}>
                                        <div className="text-lg pb-1 text-center" style={{ fontFamily: 'small-font', color: '#409c78' }}
                                        >
                                            {!!questionStatus[num] ? parseFloat(questionStatus[num]?.selectTime).toFixed(1) : '5.6'} SEC
                                        </div>
                                        <img src={mooseImg} alt='moose' className="" />
                                    </div>
                                    <div className='w-full h-full flex justify-center items-center'>
                                        {isCorrect === 1 ? ( //original- 1
                                            <img src={correctImg} className='w-2/6' alt='correct' style={{ marginBottom: '5%' }} />
                                        ) : isCorrect === 0 ? (
                                            <img src={incorrectImg} className='w-2/6' alt='incorrect' style={{ marginBottom: '5%' }} />
                                        ) : (
                                            <img src={incorrectImg} className='w-2/6 opacity-0' alt='incorrect' style={{ marginBottom: '5%' }} />
                                        )}
                                    </div>
                                    <div className="absolute bottom-1/4 mb-10 right-10 text-center"
                                        style={{ fontFamily: 'LargeFont', color: '#409c78' }}
                                    >
                                        <div className="text-2xl pb-1">{resultShowTimeCounter}</div>
                                        <div style={{ fontSize: '9px' }}>NEXT</div>
                                    </div>
                                </>
                            )}
                            <div className="absolute bottom-0 w-full flex justify-between items-center text-white max-h-12" style={{ height: '10.6vw', fontFamily: 'LargeFont', fontSize: '0.85rem' }}>
                                <div className='pl-8'>SCORE {score}</div>
                                <div className='pr-8'>{parseFloat(averageTime).toFixed(1)} SEC</div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-4 mt-2.5 text-center text-3xl" style={{ fontFamily: 'LargeFont', color: '#5652b8', fontSize: '2.05rem' }}>
                        <span>Q{num + 1}</span>
                    </div>
                    <div className="pt-3 pb-3.5 text-center leading-tight" style={{ fontFamily: 'SmallFont', margin: '0 -33px', color: '#835ab9', fontSize: '1.1rem' }}>
                        {!!questionStatus[num] ?
                            questionStatus[num]?.info?.question :
                            "Nabisco 'uncaged' the animals on its box of this after receiving criticism from PETA"}
                    </div>
                    <div className="question-images mt-0.5"
                        style={{ height: 'calc(100vw - 100px)', maxHeight: '350px' }}
                    >
                        {!!questionImageList ? questionImageList[num]?.map((item: any, key: number) =>
                            btnclick ? (
                                showcorrectanswer ? (
                                    <div key={key} className="question-image image-border">
                                        <div className="image-select">
                                            {isCorrect ? (
                                                selected === item.id && <div className="correct-answer"></div>
                                            ) : (
                                                selected === item.id ? (
                                                    <div>
                                                        <div className="color-candidate"></div>
                                                    </div>
                                                ) : item.id === questionStatus[num]?.info?.correct && <div className="correct-answer"></div>
                                            )}
                                            <div className="answer-percent" style={{ color: item.url ? '' : getColor(item.id) }}>
                                                {percent.percent === 0
                                                    ? "0"
                                                    : (
                                                        (percent.answer[key].percent /
                                                            percent.percent) *
                                                        100
                                                    ).toFixed(0)}
                                                %
                                            </div>
                                        </div>
                                        <ImgOrText item={item} />
                                    </div>
                                ) : (
                                    <div key={key} className="question-image image-border">
                                        {life === true ? (
                                            questionStatus[num].info.correct === key ||
                                                questionStatus[num].info.incorrect === key ? (
                                                <>
                                                    {selected === item.id && <div className="image-select">
                                                        <div>
                                                            <div className="color-candidate"></div>
                                                        </div>
                                                    </div>}
                                                    <ImgOrText item={item} />
                                                </>
                                            ) : (
                                                <img
                                                    src={readyImage}
                                                    alt="question"
                                                    className="ready-image"
                                                />
                                            )
                                        ) : (
                                            <>
                                                {selected === item.id && <div className="image-select">
                                                    <div>
                                                        <div className="color-candidate"></div>
                                                    </div>
                                                </div>}
                                                <ImgOrText item={item} />
                                            </>
                                        )}
                                    </div>
                                )
                            ) : (
                                <div
                                    key={key}
                                    className="question-image image-border"
                                    onClick={() => onclick_question_image(item, key)}
                                >
                                    {life === true ? (
                                        questionStatus[num].info.correct === key ||
                                            questionStatus[num].info.incorrect === key ? (
                                            <>
                                                {selected === item.id && <div className="image-select">
                                                    <div>
                                                        <div className="color-candidate"></div>
                                                    </div>
                                                </div>}
                                                <ImgOrText item={item} />
                                            </>
                                        ) : (
                                            <img
                                                src={readyImage}
                                                alt="question"
                                                className="ready-image"
                                            />
                                        )
                                    ) : (
                                        <>
                                            {selected === item.id && <div className="image-select">
                                                <div>
                                                    <div className="color-candidate"></div>
                                                </div>
                                            </div>}
                                            <ImgOrText item={item} />
                                        </>
                                    )}
                                </div>
                            )
                        ) : (
                            <>
                                <div className="question-image image-border">
                                    <img
                                        src={readyImage}
                                        alt="question"
                                        className="ready-image"
                                    />
                                </div>
                                <div className="question-image image-border">
                                    <img
                                        src={readyImage}
                                        alt="question"
                                        className="ready-image"
                                    />
                                </div>
                                <div className="question-image image-border">
                                    <img
                                        src={readyImage}
                                        alt="question"
                                        className="ready-image"
                                    />
                                </div>
                                <div className="question-image image-border">
                                    <img
                                        src={readyImage}
                                        alt="question"
                                        className="ready-image"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div className='absolute top-0 left-0 w-full h-full overflow-hidden' style={{ zIndex: '-1' }}>
                        <LazyLoadImage
                            className=""
                            alt='background'
                            src={backgroundCloudImg}
                            placeholderSrc={smallBackgroundCloudImg}
                            // effect="blur"
                            delayTime={1}
                        />
                        <div className='absolute bottom-3/4 h-full w-full right-2/4 translate-x-1/2 mb-3' >
                            <img src={helicopterImg} alt="helicopter Image" className={classNames("absolute bottom-0 w-10/12", styles['helicopter-animation'])} />
                        </div>
                    </div>
                    <div className="absolute top-1/4 left-0 w-full mt-16 text-8xl text-center text-indigo-600"
                        style={{ fontFamily: 'LargeFont' }}
                    >
                        {ready}
                    </div>
                    <div className='absolute bottom-0 left-0 w-full'
                        style={{ height: '52vw', maxHeight: '240px' }}>
                        <div className="w-full h-full flex justify-center items-center">
                            {!turnon && (
                                <div className="px-5 text-white mb-3 text-xs"
                                    style={{ backgroundColor: '#0185a6', paddingTop: '15px', paddingBottom: '15px', fontFamily: 'LargeFont' }}
                                    onClick={onclick_soundbtn}>
                                    ENABLE SOUND
                                </div>
                            )}
                        </div>
                        <img src={foregroundWithoutMooseImg} alt="bottom Image"
                            className='absolute bottom-0 w-full' style={{ zIndex: '-1' }} />
                        <img src={mooseImg} alt="bottom Image"
                            className='absolute left-12 pb-1' style={{ width: '18%', bottom: '22%' }} />
                    </div>
                </>
            )}
        </div>
    )
};

export default LivegameGuest;
