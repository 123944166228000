import React, { useContext, useEffect, useState } from "react";
import LazyLoad, { forceVisible } from "react-lazyload";
import { UserContext } from "../../providers/UserContext";
import { useNavigate } from "react-router-dom";
import readyImage from "../../assets/icons/image.png";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useSound from "use-sound";
import backgroundImg from "../../assets/icons/background-clear.png";
import smallBackgroundImg from "../../assets/icons/background-clear-small.png";
import backgroundCloudImg from "../../assets/icons/background.png";
import smallBackgroundCloudImg from "../../assets/icons/background-small.png";
import foregroundWithoutMooseImg from "../../assets/icons/foreground-game.png";
import smallForegroundWithoutMooseImg from "../../assets/icons/foreground-game-small.png";
import mooseImg from "../../assets/icons/moose.png";
import correctImg from "../../assets/icons/correctImg.png";
import incorrectImg from "../../assets/icons/incorrectImg.png";
import lifelineImg from "../../assets/icons/lifeline.png";
import helicopterImg from "../../assets/icons/get-ready-banner.png";
import classNames from 'classnames';
import styles from './styles.module.css';
import ImgOrText  from "../../components/ImgOrText";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // import css for blur effect

const Practice = () => {
  const navigate = useNavigate();
  const {
    questionlist,
    questionImageList,
    score,
    setScore,
    setMenu,
    averageTime,
    setAverageTime,
    soundPlay,
    setSoundPlay,
    // isSound,
    setResult,
    setCollection
    // turnon,
    // setTurnOn
  } = useContext(UserContext);
  const [num, setNum] = useState<number>(0);
  const [countdown, setCountDown] = useState<number>(10);
  const [resultShowTimeCounter, setResultShowTimeCounter] =
    useState<number>(10);
  const [isFinish, setIsFinish] = useState<boolean>(false);
  const [screenStatus, setScreenStatus] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>(-1);
  const [isCorrect, setIsCorrect] = useState<number>(2);
  const [btnclick, setBtnClick] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<number>(5);
  const [delayForAnswer, setDelayForAnswer] = useState<number>(5);
  const [showcorrectanswer, setShowCorrectAnser] = useState<boolean>(false);
  const [life, setLife] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const [avTime, setAvTime] = useState<any>(0);
  const [lifebtn, setLifeBtn] = useState<boolean>(true);
  const [timeup, setTimeUp] = useState<boolean>(false);
  const [turnon, setTurnOn] = useState<boolean>(false);
  const [screenHeight, setScreenHeight] = useState<number | null>();
  const { height } = useWindowDimensions();
  const [btn_50, setBtn_50] = useState<boolean>(false);
  const [correctPlay, setCorrectPlay] = useState<boolean>(false);
  const [incorrectPlay, setIncorrectPlay] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [play] = useSound("./countdown.mp3");
  const [play_50] = useSound("./50-50.mp3", { volume: 0.6 });
  const [play_correct] = useSound("./correct.wav");
  const [play_incorrect] = useSound("./incorrect.wav");
  const [play_button] = useSound("./buttons.wav");

  useEffect(() => {
    if (questionlist === undefined) {
      navigate("/");
      setMenu(0);
      setSoundPlay(false);
    }
    if (isPending === false) {
      setAverageTime(0);
      setResult(false);
      setScore(0);
    }
    setAvTime(0);
    setScreenHeight(height);
    forceVisible();
  }, []);

  useEffect(() => {
    if (turnon === true) {
      // if (isSound === true) {
      if (soundPlay === true) {
        if (visible) {
          play();
          setVisible(false);
        }
      }
      if (btn_50 === true) {
        play_50();
        setBtn_50(false);
      }
      if (correctPlay) {
        play_correct();
        setCorrectPlay(false);
      }
      if (incorrectPlay) {
        play_incorrect();
        setIncorrectPlay(false);
      }
      // }
    }
  }, [soundPlay, btn_50, correctPlay, incorrectPlay]);

  useEffect(() => {
    if (isPending === false) {
      const temp = setInterval(() => {
        setIsReady((prev) => prev - 1);
      }, 1000);

      if (isReady <= 0) {
        setIsReady(0);
        setSoundPlay(true);
        setVisible(true);
        clearInterval(temp);
        setIsPending(true);
        setCurrentTime(new Date());
      }
      return () => {
        clearInterval(temp);
      };
    }
  }, [isReady]);

  useEffect(() => {
    if (isPending === true) {
      question_result();
      const temp = setInterval(() => {
        setCountDown((prev) => prev - 1);
      }, 1000);
      if (countdown <= 0) {
        setSoundPlay(false);
        setVisible(false);
        setCountDown(0);
        clearInterval(temp);
        setIsFinish(true);
        setDelayForAnswer(5);
        setTimeUp(true);
      }
      return () => {
        clearInterval(temp);
      };
    }
  }, [countdown, isPending]);

  useEffect(() => {
    if (isFinish === true) {
      if (btnclick === false) {
        let t = 10;
        let bb = ((averageTime * num + 10) / (num + 1)).toFixed(1);
        questionlist[num].selectTime = t.toFixed(1);
        setAvTime(bb);
      }
      setBtnClick(true);
      setLife(false);
      const temp = setInterval(() => {
        setDelayForAnswer((prev) => prev - 1);
      }, 1000);

      if (delayForAnswer <= 0) {
        setAverageTime(avTime);
        setResultShowTimeCounter(10);
        setShowCorrectAnser(true);
        if (isCorrect === 1) {
          setCorrectPlay(true);
          setScore((prev) => prev + 1);
        } else {
          setIncorrectPlay(true);
        }
        setScreenStatus(true);
        setTimeUp(false);
        clearInterval(temp);
      }

      return () => {
        clearInterval(temp);
      };
    }
  }, [isFinish, delayForAnswer]);

  useEffect(() => {
    if (showcorrectanswer === true) {
      const temp = setInterval(() => {
        setResultShowTimeCounter((prev) => prev - 1);
      }, 1000);      
      // if (resultShowTimeCounter === 3) {
      //   clearInterval(temp); return;
      // }
      if (resultShowTimeCounter <= 0) {
        if (num + 1 < questionlist.length) {
          setResultShowTimeCounter(0);
          setScreenStatus(false);
          setIsFinish(false);
          clearInterval(temp);
          setSelected(-1);
          setCountDown(10);
          setSoundPlay(true);
          setVisible(true);
          setCurrentTime(new Date());
          setResultShowTimeCounter(10);
          setIsCorrect(2);
          setBtnClick(false);
          setShowCorrectAnser(false);
          setNum((prev) => prev + 1);
        } else if (num + 1 === questionlist.length) {
          setCollection(true)
          navigate("/result");
        }
        clearInterval(temp);
      }
      return () => {
        clearInterval(temp);
      };
    }
  }, [resultShowTimeCounter, showcorrectanswer]);

  const onclick_lifeline = () => {
    if (!btnclick) {
      setBtn_50(true);
      setLifeBtn(false);
      setLife(true);
    }
  };

  const onclick_soundbtn = () => {
    play_correct();
    setTurnOn(true);
  };

  const question_result = async () => {
    if (selected === questionlist[num]?.info?.correct) {
      setIsCorrect(1);
      questionImageList[num][selected].isCorrect = true;
    } else {
      setIsCorrect(0);
    }
  };

  const onclick_question_image = (item: any, key: number) => {
    play_button();
    let getTime = new Date();
    questionImageList[num][key].isSelected = true;
    setSelected(item.id);
    let aa = ((getTime.getTime() - currentTime.getTime()) / 1000).toFixed(1);
    //@ts-ignore
    let bb = ((averageTime * num + Number(aa)) / (num + 1)).toFixed(1);
    setAvTime(bb);
    questionlist[num].selectTime = aa;
    setBtnClick(true);
  };
  
  return (
    <div
        className="relative w-full overflow-hidden"
        style={{
            height: screenHeight ? screenHeight + "px" :  "100vh" ,
            padding : '0 50px', 
        }}
    >
        {isPending === true ? (   //original - true
            <>
                <div className='absolute w-full h-full top-0 left-0' style={{zIndex : '-1'}}>
                    <LazyLoadImage
                      className=""
                      alt='background'
                      src={backgroundImg}
                      placeholderSrc={smallBackgroundImg}
                      // effect="blur"
                      delayTime={1} 
                    />
                </div>
                <div className='absolute w-full h-full left-0'>
                    <div className='absolute bottom-0 w-full'
                        style={{height: '52vw', maxHeight : '240px'}}
                    >
                        <img src={foregroundWithoutMooseImg} alt='foreground' 
                            className='absolute bottom-0 w-full' style={{zIndex : '-1'}}/>
                        { screenStatus === false ? ( //original-false
                            <>
                                <img src={mooseImg} alt="bottom Image" 
                                    className='absolute left-12 pb-1' style={{width : '18%', bottom : '22%'}}/>
                                <div className='w-full h-full flex justify-center items-center'>
                                    <div className='text-6xl text-center px-8 relative' style={{fontFamily : 'LargeFont', color : '#409c78', paddingBottom : '5%'}}>
                                        <div style={{paddingLeft : 'calc(0.14 * 1em)'}}>{countdown}</div>
                                        <div className={classNames("absolute left-0 text-xl w-full text-center", countdown===0?'':'opacity-0')} 
                                            style={{fontFamily : 'small-font', color : '#409c78', transform : 'tranlateX(-50%)', bottom : '-10px' }}>
                                            TIME'S UP!
                                        </div>
                                    </div>
                                </div>
                                <img src={lifelineImg} alt='lifeline' className={classNames("absolute bottom-1/4 mb-10 right-10 w-1/12 object-contain", lifebtn ? '' : 'opacity-0')}              
                                    onClick={onclick_lifeline}
                                /> 
                            </>
                        ) : ( 
                            <>
                                <div className="absolute left-12 pb-1" style={{width : '18%', bottom : '22%'}}>
                                    <div className="text-lg pb-1 text-center" style={{fontFamily : 'small-font', color : '#409c78'}}
                                    >
                                        {!!questionlist[num] ? questionlist[num]?.selectTime : '5.6'} SEC
                                    </div>
                                    <img src={mooseImg} alt='moose' className=""/>
                                </div>
                                <div className='w-full h-full flex justify-center items-center'>
                                    { isCorrect === 1 ? ( //original- 1
                                        <img src={correctImg} className='w-2/6' alt='correct' style={{marginBottom:'5%'}}/>
                                    ) : isCorrect === 0 ? (
                                        <img src={incorrectImg} className='w-2/6' alt='incorrect'  style={{marginBottom:'5%'}}/>
                                    ) : (
                                        <img src={incorrectImg} className='w-2/6 opacity-0' alt='incorrect'  style={{marginBottom:'5%'}}/>
                                    )}
                                </div>
                                <div className="absolute bottom-1/4 mb-10 right-10 text-center"
                                    style={{fontFamily : 'LargeFont', color : '#409c78'}}
                                >
                                    <div className="text-2xl pb-1">{resultShowTimeCounter}</div>
                                    <div style={{fontSize : '9px'}}>NEXT</div>
                                </div>
                            </>
                        )}
                        <div className="absolute bottom-0 w-full flex justify-between items-center text-white max-h-12" style={{height : '10.6vw', fontFamily : 'LargeFont', fontSize : '0.85rem'}}>
                            <div className='pl-8'>SCORE {score}</div>
                            <div className='pr-8'>{averageTime} SEC</div>
                        </div>
                    </div>
                </div>
                <div className="pt-4 mt-2.5 text-center text-3xl" style={{fontFamily : 'LargeFont', color : '#5652b8', fontSize : '2.05rem' }}>
                    <span>Q{num + 1}</span>
                </div>
                <div className="pt-3 pb-3.5 text-center leading-tight"  style={{fontFamily : 'SmallFont', margin : '0 -33px', color : '#835ab9', fontSize : '1.1rem'}}>
                    {!!questionlist[num] ? 
                        questionlist[num]?.info?.question :
                        "Nabisco 'uncaged' the animals on its box of this after receiving criticism from PETA" }
                </div>
                <div className="question-images mt-0.5">
                  {questionImageList[num]?.map((item: any, key: number) =>
                    btnclick ? (
                      showcorrectanswer ? (
                          <div key={key} className="question-image image-border">
                            <div className="image-select">
                              {isCorrect ? (
                                selected === item.id && (
                                    <div className="correct-answer"></div> )
                              ) : (
                                selected === item.id ? (
                                  <div className="color-candidate"></div>
                                ) : item.id === questionlist[num]?.info?.correct && (
                                  <div className="correct-answer"></div>
                                )
                              )}
                            </div>
                            <ImgOrText item={item} />
                          </div>
                      ) : (
                        <div key={key} className="question-image image-border">
                          {life === true ? (
                            questionlist[num].info.correct === key ||
                            questionlist[num].info.incorrect === key ? (
                              <>
                                  { selected === item.id  && <div className="image-select">
                                      <div>
                                          <div className="color-candidate"></div>
                                      </div>
                                  </div> }
                                  <ImgOrText item={item} />
                              </>
                            ) : (
                              <img
                                src={readyImage}
                                alt="question"
                                className="ready-image"
                              />
                            )
                          ) :  (
                            <>
                              { selected === item.id && (
                                <div className="image-select">
                                  <div>
                                    <div className="color-candidate"></div>
                                  </div>
                                </div>
                              )}
                              <ImgOrText item={item} />
                            </>
                          )}
                        </div>
                      )
                    ) : (
                      <div
                        key={key}
                        className="question-image image-border"
                        onClick={() => onclick_question_image(item, key)}
                      >
                        {life === true ? (
                          questionlist[num].info.correct === key ||
                          questionlist[num].info.incorrect === key ? (
                              <>
                                { selected === item.id && (
                                  <div className="image-select">
                                    <div className="color-candidate"></div>
                                  </div>
                                )}
                                <ImgOrText item={item} />
                              </>
                            )  : (
                            <img
                              src={readyImage}
                              alt="question"
                              className="ready-image"
                            />
                          )
                        ) :  (
                          <>
                            {selected === item.id && (
                              <div className="image-select">
                                <div>
                                  <div className="color-candidate"></div>
                                </div>
                              </div>
                            )}
                            <ImgOrText item={item} />
                          </>
                        ) }
                      </div>
                    )
                  )}
                </div>
            </>
        ) : (
            <>
                <div className='absolute top-0 left-0 w-full h-full overflow-hidden' style={{zIndex : '-1'}}>
                    <LazyLoadImage
                      className=""
                      alt='background'
                      src={backgroundCloudImg}
                      placeholderSrc={smallBackgroundCloudImg}
                      // effect="blur"
                      delayTime={1} 
                    />
                    <div className='absolute bottom-3/4 h-full w-full right-2/4 translate-x-1/2 mb-3' >
                        <img src={helicopterImg} alt="helicopter Image" className={classNames("absolute bottom-0 w-10/12", styles['helicopter-animation'])} />
                    </div>
                </div>
                <div className="absolute top-1/4 left-0 w-full mt-16 text-8xl text-center text-indigo-600"
                    style={{fontFamily : 'LargeFont'}}
                >
                    {isReady}
                </div>
                <div className='absolute bottom-0 left-0 w-full' 
                    style={{height: '52vw', maxHeight : '240px'}}>
                    <div className="w-full h-full flex justify-center items-center">
                        {!turnon && (
                            <div className="px-5 text-white mb-3 text-xs" 
                                style={{backgroundColor : '#0185a6',paddingTop : '15px', paddingBottom : '15px', fontFamily: 'LargeFont'}} 
                                onClick={onclick_soundbtn}>
                                ENABLE SOUND
                            </div>
                        )}
                    </div>
                    <img src={foregroundWithoutMooseImg} alt="bottom Image" 
                        className='absolute bottom-0 w-full' style={{zIndex : '-1'}}/>
                    <img src={mooseImg} alt="bottom Image" 
                        className='absolute left-12 pb-1' style={{width : '18%', bottom : '22%'}}/>
                </div>
            </>
        )}
    </div>
  );
};

export default Practice;
