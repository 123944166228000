import {auth} from "./firebase";
import axios from 'axios';

export const signUpFunc = async ( email = '', password = '', username = '') => {
  try {    
    const res:any = await axios.post('/user/check-name', {email, username});
  } catch(error:any) {
    if (error.response) {
      if (error.response.status === 400) return { status : 'error', message : error.response.data.message};
      else return {status : 'error', message : 'Server error'}
    }     
  }                 
  try {
    const userCredential:any = await auth.createUserWithEmailAndPassword(email, password);
    console.log("userCredential", userCredential.user.uid, email, password)
    await axios.post('/user/add', {email, username, uid : userCredential.user.uid });
    userCredential.user.sendEmailVerification();    
    return {status : 'ok'};
  } catch (err:any) {
    console.log("error", err);
    return {status : "error", message : err.message}
  }
};

export const getUserInfo = async () => {
  try {
    const userInfo = await axios.get(`/user`);
    return userInfo.data.data;
  } catch (err) {
    console.log("user info error", err);
    return false;
  }
}

export const signInFunc = async ( email = '', password = '' ) =>{
  // console.log("email,pass", email, password)
  const userCredential:any = await auth.signInWithEmailAndPassword(email, password)
  const AUTH_TOKEN = 'Bearer ' + await userCredential.user.getIdToken();
  localStorage.setItem('AUTH_TOKEN', AUTH_TOKEN);
  localStorage.setItem('time', (Date.now()).toString())
  // console.log("token", AUTH_TOKEN)
  axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
  return userCredential;
};

export const sendPasswordReset = async ( email = '' ) =>{
  return await auth.sendPasswordResetEmail(email)
};

export const signOutFunc = () => auth.signOut();