import React, { createContext, useState } from "react";

type Props = {
    children: React.ReactNode;
};

type UserContextType = {
    menu: number;
    setMenu: React.Dispatch<React.SetStateAction<number>>;
    status: number;
    setStatus: React.Dispatch<React.SetStateAction<number>>;
    isAuth: boolean;
    setIsAuth: React.Dispatch<React.SetStateAction<boolean>>;
    questionlist: any;
    setQuestionList: React.Dispatch<React.SetStateAction<any>>;
    questionImageList: any;
    setQuestionImageList: React.Dispatch<React.SetStateAction<any>>;
    score: number;
    setScore: React.Dispatch<React.SetStateAction<number>>;
    rank: number;
    setRank: React.Dispatch<React.SetStateAction<any>>;
    user: string;
    setUser: React.Dispatch<React.SetStateAction<any>>;
    isLive: boolean;
    setIsLive: React.Dispatch<React.SetStateAction<boolean>>;
    game_num: number;
    setGameNum: React.Dispatch<React.SetStateAction<number>>;
    startTime: number;
    setStartTime: React.Dispatch<React.SetStateAction<number>>;
    prev_game_num: number;
    setPrevGameNum: React.Dispatch<React.SetStateAction<number>>;
    prev_startTime: number;
    setPrevStartTime: React.Dispatch<React.SetStateAction<number>>;
    userInfo: any;
    setUserInfo: React.Dispatch<React.SetStateAction<any>>;
    allRank: any;
    setAllRank: React.Dispatch<React.SetStateAction<any>>;
    ready: number;
    setReady: React.Dispatch<React.SetStateAction<number>>;
    delayTime: number;
    setDelayTime: React.Dispatch<React.SetStateAction<number>>;
    isDisable: boolean;
    setIsDisable: React.Dispatch<React.SetStateAction<boolean>>;
    averageTime: any;
    setAverageTime: React.Dispatch<React.SetStateAction<any>>;
    isPending: boolean;
    setIsPending: React.Dispatch<React.SetStateAction<boolean>>;
    isSound: boolean;
    setIsSound: React.Dispatch<React.SetStateAction<boolean>>;
    questionId: string;
    setQuestionId: React.Dispatch<React.SetStateAction<string>>;
    imageUrlList: Array<any>;
    setImageUrlList: React.Dispatch<React.SetStateAction<Array<any>>>;
    result: boolean;
    setResult: React.Dispatch<React.SetStateAction<boolean>>;
    questionStatus: any;
    setQuestionStatus: React.Dispatch<React.SetStateAction<any>>;
    soundPlay: boolean;
    setSoundPlay: React.Dispatch<React.SetStateAction<boolean>>;
    flag: number;
    setFlag: React.Dispatch<React.SetStateAction<number>>;
    tabStatus: boolean;
    setTabStatus: React.Dispatch<React.SetStateAction<boolean>>;
    isSpeed: boolean;
    setIsSpeed: React.Dispatch<React.SetStateAction<boolean>>;
    turnon: boolean,
    setTurnOn: React.Dispatch<React.SetStateAction<boolean>>;
    timeCounter: number;
    setTimeCounter: React.Dispatch<React.SetStateAction<number>>;
    avator: string;
    setAvator: React.Dispatch<React.SetStateAction<string>>;
    sync: boolean;
    setSync: React.Dispatch<React.SetStateAction<boolean>>;
    level: boolean;
    setLevel: React.Dispatch<React.SetStateAction<boolean>>;
    header: string;
    setHeader: React.Dispatch<React.SetStateAction<string>>;
    subheader: string;
    setSubHeader: React.Dispatch<React.SetStateAction<string>>;
    isCollection: boolean;
    setCollection: React.Dispatch<React.SetStateAction<boolean>>;
};

export const UserContext = createContext<UserContextType>({
    menu: 0,
    setMenu: () => { },
    status: 0,
    setStatus: () => { },
    isAuth: false,
    setIsAuth: () => { },
    questionlist: [],
    setQuestionList: () => { },
    questionImageList: [],
    setQuestionImageList: () => { },
    score: 0,
    setScore: () => { },
    rank: 0,
    setRank: () => { },
    user: "",
    setUser: () => { },
    isLive: false,
    setIsLive: () => { },
    game_num: 0,
    setGameNum: () => { },
    startTime: 0,
    setStartTime: () => { },
    prev_game_num: 0,
    setPrevGameNum: () => { },
    prev_startTime: 0,
    setPrevStartTime: () => { },
    userInfo: {},
    setUserInfo: () => { },
    allRank: [],
    setAllRank: () => { },
    ready: 60,
    setReady: () => { },
    delayTime: 0,
    setDelayTime: () => { },
    isDisable: true,
    setIsDisable: () => { },
    averageTime: 0,
    setAverageTime: () => { },
    isPending: false,
    setIsPending: () => { },
    isSound: true,
    setIsSound: () => { },
    questionId: "",
    setQuestionId: () => { },
    imageUrlList: [],
    setImageUrlList: () => { },
    result: false,
    setResult: () => { },
    questionStatus: [],
    setQuestionStatus: () => { },
    soundPlay: false,
    setSoundPlay: () => { },
    flag: 0,
    setFlag: () => { },
    tabStatus: false,
    setTabStatus: () => { },
    isSpeed: false,
    setIsSpeed: () => { },
    turnon: false,
    setTurnOn: () => { },
    timeCounter: 0,
    setTimeCounter: () => { },
    avator: "",
    setAvator: () => { },
    sync: false,
    setSync: () => { },
    level: false,
    setLevel: () => { },
    header: "",
    setHeader: () => {},
    subheader: "",
    setSubHeader: () => {},
    isCollection: false,
    setCollection: () => {}
});

const UserProvider: React.FC<Props> = ({ children }) => {
    const [menu, setMenu] = useState(0);
    const [isAuth, setIsAuth] = useState(false);
    const [status, setStatus] = useState(0);
    const [questionlist, setQuestionList] = useState();
    const [questionImageList, setQuestionImageList] = useState();
    const [score, setScore] = useState(0);
    const [rank, setRank] = useState(0)
    const [user, setUser] = useState("");
    const [isLive, setIsLive] = useState(false);
    const [game_num, setGameNum] = useState(0);
    const [startTime, setStartTime] = useState(0);
    const [prev_game_num, setPrevGameNum] = useState(0);
    const [prev_startTime, setPrevStartTime] = useState(0);
    const [userInfo, setUserInfo] = useState({});
    const [allRank, setAllRank] = useState();
    const [ready, setReady] = useState(60);
    const [delayTime, setDelayTime] = useState(-2);
    const [isDisable, setIsDisable] = useState(true);
    const [averageTime, setAverageTime] = useState(0);
    const [isPending, setIsPending] = useState(false);
    const [isSound, setIsSound] = useState(true);
    const [questionId, setQuestionId] = useState("");
    const [imageUrlList, setImageUrlList] = useState<Array<any>>([]);
    const [result, setResult] = useState(false);
    const [questionStatus, setQuestionStatus] = useState([]);
    const [soundPlay, setSoundPlay] = useState(false);
    const [flag, setFlag] = useState<number>(0);
    const [tabStatus, setTabStatus] = useState<boolean>(false);
    const [isSpeed, setIsSpeed] = useState(false);
    const [turnon, setTurnOn] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(0);
    const [avator, setAvator] = useState<string>("guest");
    const [sync, setSync] = useState<boolean>(false);
    const [level, setLevel] = useState<boolean>(false);
    const [subheader, setSubHeader] = useState<string>("");
    const [header, setHeader] = useState<string>("");
    const [isCollection, setCollection] = useState<boolean>(false);

    return (
        <UserContext.Provider value={{ menu, setMenu, status, setStatus, isAuth, setIsAuth, questionlist, setQuestionList, questionImageList, setQuestionImageList, score, setScore, rank, setRank, user, setUser, isLive, setIsLive, game_num, setGameNum, startTime, setStartTime, prev_game_num, setPrevGameNum, prev_startTime, setPrevStartTime, userInfo, setUserInfo, allRank, setAllRank, ready, setReady, delayTime, setDelayTime, isDisable, setIsDisable, averageTime, setAverageTime, isPending, setIsPending, isSound, setIsSound, questionId, setQuestionId, imageUrlList, setImageUrlList, result, setResult, questionStatus, setQuestionStatus, soundPlay, setSoundPlay, flag, setFlag, tabStatus, setTabStatus, isSpeed, setIsSpeed, turnon, setTurnOn, timeCounter, setTimeCounter, avator, setAvator, sync, setSync, level, setLevel, header, setHeader, subheader, setSubHeader, isCollection, setCollection }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
