import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../providers/UserContext";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useSound from "use-sound";
import backgroundImg from "../../assets/icons/background-clear.png";
import foregroundWithoutMooseImg from "../../assets/icons/foreground-game.png";
import mooseImg from "../../assets/icons/moose.png";
import correctImg from "../../assets/icons/correctImg.png";
import incorrectImg from "../../assets/icons/incorrectImg.png";
import readyImage from "../../assets/icons/image.png";
import classNames from 'classnames';
import { toast, ToastContainer } from "react-toastify";
import ImgOrText, { getColor } from "../../components/ImgOrText";

const ReviewMyAnswer = () => {
    const navigate = useNavigate();
    const { questionImageList, questionlist, setMenu, isLive, questionStatus, score, averageTime } =
        useContext(UserContext);
    const [num, setNum] = useState<number>(0);
    const [isCorrect, setIsCorrect] = useState<boolean>(false);
    const [screenHeight, setScreenHeight] = useState<number | null>();
    const { height } = useWindowDimensions();
    const [play_button] = useSound("./buttons.wav");

    useEffect(() => {
        setMenu(2);
        setScreenHeight(height);
        if (!questionlist) navigate('/');
    }, []);

    useEffect(() => {
        let i = 0;
        if (!!questionImageList) questionImageList[num]?.map((item: any, key: number) => {
            if (item.isCorrect === true) {
                setIsCorrect(true);
            } else if (item.isCorrect === false) {
                setIsCorrect(false);
            } else {
                i++;
            }
            if (i === 4) {
                setIsCorrect(false);
            }
        });
    }, [num]);

    const previous_question = () => {
        play_button();
        if (!!questionImageList && num > 0) {
            setNum((prev) => prev - 1);
        }
    };

    const next_question = () => {
        play_button();
        if (!!questionImageList && num < questionImageList.length - 1) {
            setNum((prev) => prev + 1);
        }
    };

    const onclick_NFT = (item: any) => {
        if (item?.url && item?.url_nft) {
            play_button();
            window.open(item?.url_nft);
        }
    }

    return (
        <div
            className="relative w-full overflow-hidden"
            style={{
                height: screenHeight ? screenHeight + "px" : "100vh",
                padding: '0 50px',
            }}
        >
            <div className='absolute w-full h-full top-0 left-0' style={{ zIndex: '-1' }}>
                <img src={backgroundImg} alt="background" />
            </div>
            <div className='absolute left-6 top-7 text-white text-xs cursor-pointer'
                style={{ fontFamily: 'LargeFont' }}
                onClick={() => navigate('/result')}
            >
                BACK
            </div>
            <div className='absolute w-full h-full left-0' style={{ zIndex: '-1' }}>
                <div className='absolute bottom-0 w-full'
                    style={{ height: '52vw', maxHeight: '240px' }}
                >
                    <img src={foregroundWithoutMooseImg} alt='foreground'
                        className='absolute bottom-0 w-full' style={{ zIndex: '-1' }} />
                    <div className="absolute left-8 pb-1" style={{ width: '20%', bottom: '22%' }}>
                        <div className="text-lg pb-1 text-center" style={{ fontFamily: 'small-font', color: '#409c78' }}
                        >
                            {isLive ? (
                                <>
                                    {!!questionStatus[num] ? parseFloat(questionStatus[num]?.selectTime).toFixed(1) : '5.6'} SEC
                                </>
                            ) : (
                                <>
                                    {!!questionlist && !!questionlist[num] ? questionlist[num]?.selectTime : '5.6'} SEC
                                </>
                            )}
                        </div>
                        <img src={mooseImg} alt='moose' className="" />
                    </div>
                    <div className='w-full h-full flex justify-center items-center'>
                        {isCorrect ? (
                            <img src={correctImg} className='w-2/6' alt='correct' style={{ marginBottom: '5%' }} />
                        ) : (
                            <img src={incorrectImg} className='w-2/6' alt='incorrect' style={{ marginBottom: '5%' }} />
                        )}
                    </div>
                    <div className="absolute bottom-0 w-full flex justify-between items-center text-white max-h-12" style={{ height: '10.6vw', fontFamily: 'LargeFont', fontSize: '0.85rem' }}>
                        <div className='pl-8'>SCORE {score}</div>
                        <div className='pr-8'>{parseFloat(averageTime).toFixed(1)} SEC</div>
                    </div>
                </div>
            </div>
            <div className="pt-4 mt-3.5 text-center text-3xl flex justify-evenly items-end">
                <div className={classNames("question-move-previous", num === 0 ? 'opacity-0' : '')}
                    style={{ color: '#5653b9', marginBottom: '0px' }}
                    onClick={previous_question}
                >
                    <FaAngleLeft className="!w-5 !h-5" />
                    <span className="px-2 leading-none text-sm">Q{num}</span>
                </div>
                <span style={{ fontFamily: 'LargeFont', color: '#5652b8', fontSize: '2.05rem' }}>
                    Q{num + 1}
                </span>
                <div className={classNames("question-move-next", !!questionlist && num + 1 === questionlist.length ? 'opacity-0' : '')}
                    style={{ color: '#5653b9', marginBottom: '0px' }}
                    onClick={next_question}
                >
                    <span className="px-2 leading-none text-sm">Q{num + 2}</span>
                    <FaAngleRight className="!w-5 !h-5" />
                </div>
            </div>
            <div className="pt-2.5 pb-3.5 text-center leading-tight" style={{ fontFamily: 'SmallFont', margin: '0 -33px', color: '#835ab9', fontSize: '1.1rem' }}>
                {!!questionlist && questionlist[num] ?
                    questionlist[num]?.info?.question :
                    "Nabisco 'uncaged' the animals on its box of this after receiving criticism from PETA"}
            </div>
            <div className="question-images mt-0.5">
                {!!questionImageList ? questionImageList[num]?.map((item: any, key: number) => (
                    //@ts-ignore
                    <div
                        key={key}
                        className="question-image image-border"
                        onClick={() => onclick_NFT(item)}
                    >
                        <div className="image-select">
                            {item.isSelected ? (
                                <>
                                    {item.isCorrect ? (
                                        <div>
                                            <div className="correct-answer"></div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="color-candidate"></div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    {item.id === questionlist[num].info.correct && (
                                        <div>
                                            <div className="correct-answer"></div>
                                        </div>
                                    )}
                                </>
                            )}
                            {isLive && (
                                <div className="answer-percent" style={{ color: item.url ? '' : getColor(item.id) }}>
                                    {questionlist[num].percent === 0
                                        ? "0"
                                        : (
                                            (questionlist[num].answer[key].percent /
                                                questionlist[num].percent) *
                                            100
                                        ).toFixed(0)}
                                    %
                                </div>
                            )}
                        </div>
                        <ImgOrText item={item} />
                    </div>
                )) : (
                    <>
                        <div className="question-image image-border">
                            <div className="image-select">
                                <div className='color-candidate'></div>
                                <div className='answer-percent'>100%</div>
                            </div>
                            <img
                                src={readyImage}
                                alt="question"
                                className="ready-image"
                            />
                        </div>
                        <div className="question-image image-border">
                            <div className="image-select">
                                <div className='correct-answer'></div>
                                <div className='answer-percent'>100%</div>
                            </div>
                            <img
                                src={readyImage}
                                alt="question"
                                className="ready-image"
                            />
                        </div>
                        <div className="question-image image-border">
                            <img
                                src={readyImage}
                                alt="question"
                                className="ready-image"
                            />
                        </div>
                        <div className="question-image image-border">
                            <img
                                src={readyImage}
                                alt="question"
                                className="ready-image"
                            />
                        </div>
                    </>
                )}
            </div>
            <ToastContainer />
        </div>
    );
};

export default ReviewMyAnswer;
