import React from 'react';
import './App.css';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import UserProvider from "./providers/UserContext";
// import Header from './pages/layout/Header';
import Home from './pages/home';
import Practice from './pages/practice/practice';
import LiveGame from './pages/livegame/livegame';
import LivegameGuest from './pages/livegame-guest/livegame-guest';
import Result from './pages/result/result';
import ReviewMyAnswer from './pages/result/review_myanswer';
import HighScore from './pages/result/highscore';
import GameRule from './pages/gamerule';
import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_FUNCTION_URL;

function App() {  
  return (
    <div className='trivia'>
      <div className="App">
        <UserProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/practice" element={<Practice />} />
              <Route path="/livegame" element={<LiveGame />} />
              <Route path="/livegame-guest" element={<LivegameGuest />} />
              <Route path="/result" element={<Result />} />
              <Route path="/review_myanswer" element={<ReviewMyAnswer />} />
              <Route path="/leaderboard" element={<HighScore />} />
              <Route path='/game_rule' element={<GameRule />} />
            </Routes>
          </Router>
        </UserProvider>
      </div>
    </div>
  );
}

export default App;
