import React from "react";

export const getColor = (id : number) => {
    let color = '#';
    switch (id) {
        case 0 : color += 'c7b8f1'; break;
        case 1 : color += '56d1e4'; break;
        case 2 : color += 'ffaea0'; break;
        case 3 : color += 'd1dc59'; break;
        default : 
    }
    return color;
}

type Props = {
    item : any
};

const ImgOrText: React.FC<Props> = ({item}) => {
    // console.log("item", item);
    let bgColor = getColor(item.id);
    return (
        <>
            {item?.url ? (
                <img src={item?.url} alt='question' className='select-image' />
            ) : (
                <div className="w-full relative"
                    style={{paddingBottom : '100%',fontFamily : 'SmallFont', backgroundColor : bgColor}}
                >   
                    <div className='absolute w-full h-full top-0  flex justify-center items-center'
                    >   
                        <div className="p-2 text-center text-white text-2xl min-w-full break-words leading-tight">  
                            {item.answer}
                        </div>        
                    </div>    
                </div>
            )}
        </>
    )
}

export default ImgOrText;