import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// import {GoogleAuthProvider, signInWithPopup, getAuth, FacebookAuthProvider, OAuthProvider} from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyBwzQ5eSTCnOMSKS9302714gzBTIuQ0cT8",
    authDomain: "trivia-challenge-9f6b5.firebaseapp.com",
    projectId: "trivia-challenge-9f6b5",
    storageBucket: "trivia-challenge-9f6b5.appspot.com",
    messagingSenderId: "373472339880",
    appId: "1:373472339880:web:daf02119564177a5689f9d",
    measurementId: "G-N956LLC23Y"
};

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth
const db = firebaseApp.firestore();
const auth = firebase.auth();
const dbfield = firebase.firestore;
const Provider = firebase.auth;

export { auth, db, dbfield, Provider };
