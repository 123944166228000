import React from "react";

import home_img from "../../assets/icons/home-header.png";
import homtMooseLogo from "../../assets/icons/home-moose-logo.png";

const header = (props: any) => {
  const { flag, status, subHeader, image, prize } = props;
  return (
    <div className="home_header">
      {(status !== 1 || flag !== 1) && (
        <img src={home_img} alt="home" className="home_image"></img>
      )}
      {status !== 1 && (
        <>
          {/* <img src={home_nft} alt="home-nft" className="home_nft"></img> */}
          <img src={homtMooseLogo} alt="moose" className="home_nft"></img>
          {!image && (
            <div className="home-prize">
              <span className="home-prize-header">1st prize</span>
              <div className="home-prize-content">
                <span>{prize ? prize : "TBD"}</span>
              </div>
              <span className="home-prize-subheader">{subHeader}</span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default header;
