import React, { useContext, useEffect, useState } from "react";
import useSound from "use-sound";
import { UserContext } from "../../providers/UserContext";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import foregroundImg from "../../assets/icons/foreground.png";
import smallForegroundImg from "../../assets/icons/foreground-small.png";
import backgroundImg from "../../assets/icons/background.png";
import smallBackgroundImg from "../../assets/icons/background-small.png";
// import helicopterImg from "../../assets/icons/helicopter.png";
import liveEndGameBannerImg from "../../assets/icons/live-end-game-banner.png";
import practiceEndGameBannerImg from "../../assets/icons/practice-end-game-banner.png";
import balloonImg from "../../assets/icons/balloon.png";
import Header from "../layout/Header";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import classNames from "classnames";
import { toast, ToastContainer } from "react-toastify";
import styles from "./styles.module.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // import css for blur effect

const Result = () => {
  const [play_button] = useSound("./buttons.wav");
  const navigate = useNavigate();
  const {
    rank,
    setMenu,
    score,
    averageTime,
    isLive,
    allRank,
    setIsLive, //
    setScore,  //
    setRank,   // 
    timeCounter,
    setTimeCounter,
    sync,
    setSync,
    setLevel,
  } = useContext(UserContext);

  const [isPending, setIsPending] = useState<boolean>(false);
  const [screenHeight, setScreenHeight] = useState<number | null>();
  const { height } = useWindowDimensions();
  const [firstPlaceName, setFirstPlaceName] = useState("no name");
  const [isDisplay, setIsDisplay] = useState<boolean>(false);

  useEffect(() => {
    /**** initial condition for static page */
    if (allRank && allRank.length > 0) {
      let liveRank = allRank.filter((item:any )=>  (!item?.prev_playtime) || (item.play_time === item?.prev_playtime))
      if (liveRank.length > 0) setFirstPlaceName(liveRank[0].username);
      else setFirstPlaceName(allRank[0].username);
    }
    setTimeout(() => setIsDisplay(true), 10);
    if (!averageTime || averageTime <= 0) navigate('/');
  }, [])

  useEffect(() => {
    setMenu(0);
    setScreenHeight(height);
  }, [rank, score, isLive, sync]);

  const onclick_leaderboard = () => {
    play_button();
    navigate("/leaderboard");
    setMenu(2);
  };

  const onclick_review_answer_btn = () => {
    setLevel(true);
    play_button();
    navigate("/review_myanswer");
    setMenu(2);
  };

  return isPending ? (
    <div className="loading-bar relative">
      <ReactLoading
        type="spin"
        width={25}
        height={25}
        color="#9d9d9d"
      ></ReactLoading>
    </div>
  ) : (
    <div
      className="result"
      style={
        screenHeight
          ? {
            height: screenHeight + "px",
          }
          : {
            height: "100vh",
          }
      }
    >
      <Header />
      <div className='absolute top-0 left-0 w-full h-full overflow-hidden' >
        <img src={backgroundImg} alt="bottom Image" />
        <div className='absolute bottom-3/4 h-full w-full right-2/4 translate-x-1/2' >
          <img src={isLive ? liveEndGameBannerImg : practiceEndGameBannerImg} alt="helicopter Image" className={classNames("absolute bottom-0 max-w-none", styles['helicopter-animation'])} style={{ height: '60px' }} />
          {/* <img src={helicopterImg} alt="helicopter Image" className={classNames("absolute bottom-0 h-4/12", styles['helicopter-animation'])} /> */}
          <img src={balloonImg} alt="balloon Image" className={classNames("absolute -bottom-8", styles['balloon-animation'])} />
        </div>
      </div>
      <div className='absolute bottom-0 left-0 w-full'>
        <img src={foregroundImg} alt="bottom Image object-contain" />
        {isLive ? (
          <div className='absolute w-full max-h-20 top-1 text-center flex flex-col justify-center text-white' style={{ height: '17.2vw', fontFamily: 'LargeFont' }}>
            <div className='pb-0.5' style={{ color: '#c07de1', fontSize: '0.6rem' }}>1ST PLACE</div>
            <div>{firstPlaceName.toUpperCase()}</div>
          </div>
        ) : (
          <div className='absolute w-full max-h-20 top-1 text-center flex flex-col justify-center text-white' style={{ height: '17.2vw', fontFamily: 'LargeFont' }}>
            <div style={{ fontSize: '1.2rem' }}>WELCOME!</div>
          </div>
        )}
        <div className="absolute top-2/3 left-0 w-full h-1/6 flex flex-col justify-center items-center" style={{ display: isDisplay ? '' : 'none' }}>
          <div className='text-md pb-3' style={{ fontFamily: 'SmallFont', color: '#409c78'}}>
            {isLive ? 'Join us on Discord for the afterparty' : 'Join us for the next live game'}
          </div>
          {isLive ? (
            <div className="grid grid-flow-col justify-stretch w-10/12 text-white mb-3" style={{ fontFamily: 'LargeFont' }}>
              <button type='button' className='text-xs mr-1.5' style={{ backgroundColor: '#50d17b', padding: '0.72rem 0' }} onClick={() => { onclick_review_answer_btn(); }}>REVIEW</button>
              <button type='button' className='text-xs ml-1.5' style={{ backgroundColor: '#50d17b', padding: '0.72rem 0' }} onClick={() => { onclick_leaderboard(); }}>TOP 25</button>
            </div>
          ) : (
            <div className="flex justify-center w-10/12 text-white mb-3" style={{ fontFamily: 'LargeFont' }}>
              <button type='button' className='text-xs w-6/12' style={{ backgroundColor: '#50d17b', padding: '0.72rem 0' }} onClick={() => { onclick_review_answer_btn(); }}>REVIEW</button>
            </div>
          )}
        </div>
        <div className="absolute bottom-0 w-full flex justify-between items-center text-white max-h-12" style={{ height: '10.6vw', fontFamily: 'LargeFont', fontSize: '0.85rem' }}>
          <div className='pl-8'>SCORE {score}</div>
          <div className='pr-8'>RANK {rank}</div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Result;
