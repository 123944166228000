/* eslint-disable react-hooks/exhaustive-deps */
import "hacktimer/HackTimer.js";
import React, { useEffect, useContext, useState } from "react";
import { UserContext } from "../../providers/UserContext";
import { useNavigate } from "react-router-dom";
import HeaderImage from "./header";
import TimeCounter from "./TimeCounter";
import EmailLogin from "./EmailLogin";
import Header from "../layout/Header";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { toast, ToastContainer } from "react-toastify";
import { HHMMSSType, secondsToHHMMSS } from "../../components/convert";
import axios from 'axios';
import { getUserInfo } from "../../services/user";

const Home = () => {
    const [screenHeight, setScreenHeight] = useState<number | null>();
    const { height } = useWindowDimensions();
    const navigate = useNavigate();
    const {
        setMenu,
        status,
        flag,
        setStatus,
        isAuth,
        setIsAuth,
        setQuestionImageList,
        questionlist,
        setQuestionList,
        setUser,
        setAvator,
        setIsLive,
        game_num,
        setGameNum,
        startTime,
        setStartTime,
        prev_game_num,
        setPrevGameNum,
        prev_startTime,
        setPrevStartTime,
        setUserInfo,
        setScore,
        setReady,
        delayTime,
        setDelayTime,
        isDisable,
        setIsDisable,
        user,
        isPending,
        setIsPending,
        setQuestionId,
        imageUrlList,
        setImageUrlList,
        setQuestionStatus,
        setSoundPlay,
        setIsSpeed,
        isSpeed,
        ready,
        header,
        setHeader,
        subheader,
        setSubHeader
    } = useContext(UserContext);
    const [liveLoading, setLiveLoading] = useState<boolean>(false);
    const [showBtn, setShowBtn] = useState<boolean>(false);
    const [isPractice, setIsPractice] = useState<boolean>(true);
    const [practice_title, setPractice_title] = useState<string>('practice');
    const [shownTime, setShownTime] = useState<HHMMSSType>({
        hrs: "00",
        mins: "00",
        secs: "00",
    });
    const [updateTime, setUpdateTime] = useState(0);
    const [shouldHideImage, setShouldHideImage] = useState<boolean>(false)
    const [timediffCS, setTimediffCS] = useState<number>(0);

    const getServerClientTimediff = async () => {
        let t1 = Date.now();
        const res: any = await axios.get('/server-time');
        let t2 = Date.now();
        return t2 - (res.data.data + (t2 - t1) / 2);
    }
    const getServertime = () => {
        return Date.now() - timediffCS;
    }
    useEffect(() => {
        setScreenHeight(height);
        if (height) {
            if (height < 600) {
                setShouldHideImage(true)
            } else {
                setShouldHideImage(false)
            }
        }
        getServerClientTimediff().then(timediff => setTimediffCS(timediff));

        axios.get('/practice/questions').then(res => {
            const questionTemp = res.data.data;
            setQuestionList(questionTemp.data.questions);
        })

        let token = localStorage.getItem('AUTH_TOKEN');
        if (!!token) {
            // console.log("token", token)
            axios.defaults.headers.common['Authorization'] = token;
            getUserInfo().then((UserCollection => {
                if (!!UserCollection) {
                    const users = {
                        isAuth: true,
                        userinfo: UserCollection,
                    };
                    localStorage.setItem("user", JSON.stringify(users));
                    setIsAuth(true);
                    setStatus(0);
                    setAvator(UserCollection?.username)
                } else {
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.reload();
                    }, 500)
                }
            }));

        } else {
            localStorage.clear();
        }

        get_phrase();


    }, []);

    useEffect(() => {
        if (status === 0) {
            setMenu(0);
        } else {
            setMenu(1);
        }
    }, [status]);

    useEffect(() => {
        get_data();
    }, [updateTime]);

    useEffect(() => {
        get_user();
        setSoundPlay(false);
    }, [user]);

    useEffect(() => {
        if (delayTime > 5 && delayTime % 10 === 5) {
            setUpdateTime((prev) => prev + 1);
        }
        let prevTime = Date.now();
        if (isDisable === true && delayTime >= -1) {
            const temp = setInterval(() => {
                let t1 = Date.now()
                if (t1 - prevTime >= 1000) {
                    setDelayTime((prev) => prev - 1);
                    let temp = secondsToHHMMSS(delayTime);
                    setShownTime(temp);
                    // console.log("ddddd", Date.now(), prevTime)
                }
            }, 100);
            if (delayTime < 0) {
                setDelayTime(-2);
                clearInterval(temp);
                livegame_btn_click1();
                return;
            }
            return () => {
                clearInterval(temp);
            };
        }
    }, [delayTime]);

    useEffect(() => {
        if (isDisable === false) {
            let interval = setInterval(() => {
                setReady((lastTimerCount) => {
                    if (lastTimerCount <= 0) {
                        setUpdateTime((prev) => prev + 1);
                        setDelayTime(-2);
                        setIsDisable(true);
                        setIsPending(false);
                        clearInterval(interval);
                    }

                    return lastTimerCount - 1;
                });
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [isDisable]);

    useEffect(() => {
        if (!!prev_game_num && prev_game_num > 0) {            
            setPractice_title(`game #${prev_game_num}`)
        }
    }, [prev_game_num])

    const get_data = async () => {
        const currentTime = getServertime();
        let timeDifference = Math.round(startTime / 1000 - currentTime / 1000);
        if (currentTime > 0 && startTime > 0) {
            console.log("timeDifference", Date.now() / 1000, timeDifference, startTime / 1000, currentTime / 1000);
            if (timeDifference < 0 || isNaN(timeDifference)) {
                setDelayTime(-2);
                setShowBtn(false)
                setIsPractice(true);
            } else {
                if (timeDifference / 60 >= 30) {
                    setIsPractice(true);
                } else {
                    setIsPractice(false);
                }
                setDelayTime(timeDifference);
                setShowBtn(true);
            }
        } else {
            let t1 = Date.now();
            const currentTimeS1: any = await axios.get('/server-time');
            let t2 = Date.now();
            let currentTimeS = currentTimeS1.data.data + (t2 - t1) / 2;
            let startTimeS = 0;
            let prev_startTimeS = 0;
            try {
                const startTimeS1 = await axios.get('/start-time');
                startTimeS = startTimeS1.data.startTime;
                if (startTimeS > 0) {
                    setStartTime(startTimeS); //ms   
                    setGameNum(startTimeS1.data.no);
                } else {
                    console.log("There is no livegame", startTimeS);
                }
                prev_startTimeS = startTimeS1.data.prev_startTime;
                if (prev_startTimeS > 0) {
                    setPrevStartTime(prev_startTimeS);
                    setPrevGameNum(startTimeS1.data.prev_no)
                } else {
                    console.log("There is no prevgame", prev_startTimeS);
                }
            } catch (err) {
                console.log("There is no livegame");
            }

            timeDifference = Math.round((startTimeS - currentTimeS) / 1000);
            console.log("timeDiff", timeDifference)
            if (timeDifference < 0 || isNaN(timeDifference)) {
                setDelayTime(-2);
                setShowBtn(false)
                setIsPractice(true);
                if (timeDifference > -315) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 315 + timeDifference);
                }
            } else {
                if (timeDifference / 60 >= 30) {
                    setIsPractice(true);
                } else {
                    setIsPractice(false);
                }
                setDelayTime(timeDifference);
                setShowBtn(true);
            }
        };
    }

    const get_phrase = async () => {
        if (header.length === 0) {
            try {
                const livegame_data_res = await axios.get('/livegame');
                let livegame = livegame_data_res.data.data;

                if (livegame.header !== undefined) {
                    setHeader(livegame.header);
                    setSubHeader(livegame.subheader)
                }
            } catch (err: any) {
                console.log("There is no live game.")
            }
        }
    };

    const get_user = () => {
        // @ts-ignore
        let user1 = localStorage.getItem("user");
        if (!!user1) {
            const isLogin = JSON.parse(user1);
            if (!!isLogin.userinfo) {
                setIsAuth(true);
                setUser(isLogin.userId);
                setAvator(isLogin.userinfo.username);
                setUserInfo(isLogin.userinfo);
            } else {
                localStorage.clear();
            }
        } else {
            setIsAuth(false);
        }
    };

    const register_btn_click = () => {
        setStatus(1);
    };

    const livegame_btn_click = async () => {
        console.log('livegame_btn_click');
    };

    const livegame_btn_click1 = async () => {
        console.log("livegame_btn_click1", isDisable, ready)
        if (isDisable === true) {
            if (ready >= 0) {
                setScore(0);
                setLiveLoading(true);
                setIsDisable(true);
                get_user();
                //@ts-ignore
                try {
                    if (!!isAuth && !!game_num && game_num > 0) navigate("/livegame");
                    setIsLive(true);
                } catch (err: any) {
                    console.log("There is no live game.")
                }
                setLiveLoading(false);
            }
        }
    };

    const practice_btn_click = async () => {
        if (!!isAuth) {
            let str = localStorage.getItem('user');
            if (!!str && str.length > 0) {
                let user1 = JSON.parse(str);
                let userinfo = user1.userinfo;
                console.log(prev_game_num, prev_startTime, userinfo.play, userinfo.play_time)
                if (prev_game_num === userinfo.play && prev_startTime === userinfo.play_time ) {
                    toast.error('It appears you have already played the most recent game.   Please join us for the next live event!')
                } else {
                    navigate('livegame-guest');
                    setIsLive(true);
                }
            }
            return;
        } else {            
            navigate("/");
            setStatus(1);
            setMenu(1);
            return;
        }
        /* for practice game */
        console.log("practice clicked", questionlist);
        setScore(0);
        if (!!questionlist) {
            navigate("/practice");
            console.log('pratice', questionlist)
            const questionListTemp = questionlist.map((question: any) => ({
                ...question,
                selectTime: 0,
            }));

            const questionImageListTemp = questionlist.map((question: any) =>
                question.answer.map((item: any, key: number) => ({
                    ...item,
                    id: key,
                }))
            );

            setQuestionImageList(questionImageListTemp);
            setQuestionStatus(questionListTemp);
            setIsLive(false);
        }
    };

    return (
        <div
            className="homecontainer"
            style={
                screenHeight
                    ? {
                        height: screenHeight + "px",
                    }
                    : {
                        height: "100vh",
                    }
            }
        >
            <Header />
            <HeaderImage flag={flag} status={status} subHeader={subheader} image={shouldHideImage} prize={header} />
            {status === 1 ? (
                <EmailLogin />
            ) : (
                <TimeCounter
                    liveLoading={liveLoading}
                    showBtn={showBtn}
                    isPractice={isPractice}
                    shownTime={shownTime}
                    isPending={isPending}
                    startTime={startTime}
                    prev_startTime={prev_startTime}
                    delayTime={delayTime}
                    practice_title = { practice_title }
                    register_btn_click={register_btn_click}
                    practice_btn_click={practice_btn_click}
                    livegame_btn_click={livegame_btn_click}
                    isDisable={isDisable}
                    isAuth={isAuth}
                    updateTime={updateTime}
                    setUpdateTime={setUpdateTime}
                    setShownTime={setShownTime}
                    secondsToHHMMSS={secondsToHHMMSS}
                />
            )}
            <ToastContainer />
        </div>
    );
};

export default Home;
