import React from "react";
import { useNavigate } from 'react-router-dom'
import ReactLoading from "react-loading";

const TimeCounter = (props: any) => {
  const navigate = useNavigate();
  const {
    liveLoading,
    showBtn,
    startTime,
    prev_startTime,
    shownTime,
    isPending,
    practice_title,
    practice_btn_click,
    livegame_btn_click,
    isDisable,
  } = props;

  let diff = Date.now() - startTime;

  return isPending ? (
    <div className="time-counter-pending">
      <ReactLoading
        type="spin"
        width={24}
        height={24}
        color="#9d9d9d"
        className="start-loading"
      ></ReactLoading>
    </div>
  ) : (
    <div className="timecounter">
      <div className="btn_group">
        <div className="btn-group-header">
          <button onClick={practice_btn_click}>{ practice_title }</button>
          <button onClick={() => navigate('/game_rule')}>game rules</button>
        </div>
        <div className="livegame_btn" onClick={livegame_btn_click}>
          {liveLoading ? (
            <ReactLoading
              type="spin"
              width={24}
              height={24}
              color="#9d9d9d"
            ></ReactLoading>
          ) : (
            <>
              <span className="countdown_hr">
                {
                showBtn
                    ? isDisable
                      ? shownTime?.hrs +
                      ":" +
                      shownTime?.mins +
                      ":" +
                      shownTime?.secs
                      : "Play Game"
                    : 'TBD'
                    }
              </span>
              <span className="countdown_ready">
                Live game begins in
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default TimeCounter;
