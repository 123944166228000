import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../providers/UserContext";
import { useNavigate } from "react-router-dom";
import collection from "../../assets/icons/opensea.png";
import { signOutFunc } from "../../services/user";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import menuIcon from "../../assets/icons/menu.png";
import discordImg from "../../assets/icons/discord1.png";
import mooseImg from "../../assets/icons/moose.png";
import classNames from "classnames";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

const Header = () => {
  const navigate = useNavigate();
  const {
    menu,
    setStatus,
    setMenu,
    user,
    setUser,
    avator,
    setAvator,
    userInfo,
    setFlag,
    flag,
    setReady,
    setIsSpeed,
    setTabStatus,
    setLevel,
    isAuth,
    setSubHeader,
    setHeader,
    isCollection,
    setAllRank,
    setCollection
  } = useContext(UserContext);
  const [visible, setVisible] = useState<number>(0);
  const [screenHeight, setScreenHeight] = useState<number | null>();
  const { height } = useWindowDimensions();
  const [isResultPage, setIsResultPage] = useState(false);

  useEffect(() => {
    setScreenHeight(height);
    console.log(flag);
    console.log(menu);
    if (window.location.pathname === '/result') setIsResultPage(true);
  }, []);

  const onclick_back = () => {
    navigate("/result");
    setMenu(0);
    setLevel(false);
  };

  const onclick_menu = () => {
    setVisible(1);
  };

  const onclick_home = () => {
    navigate("/");
    setStatus(0);
    setVisible(0);
    setFlag(0);
    setCollection(false);
    setReady(60);
    setIsSpeed(false);
    setTabStatus(false);
  };

  const onclick_home_back = () => {
    navigate("/");
    setVisible(0);
    setFlag(0);
    setReady(60);
  };

  const onClose = () => {
    setVisible(-1);
  };

  const onclick_login = () => {
    navigate("/");
    setStatus(1);
    setMenu(1);
    setVisible(0);
    setReady(60);
  };

  const onclick_signin = () => {
    setStatus(1);
  };

  const onclick_logout = async () => {
    await signOutFunc();
    localStorage.removeItem("user");
    navigate("/");
    setUser("");
    setStatus(0);
    setVisible(0);
    setReady(60);
    setAvator("guest");
    setSubHeader("");
    setHeader("");
  };

  const onclick_collection = () => {
    console.log("b");

    setVisible(0);
    window.open("https://opensea.io/collection/noundles", "_blank");
  };

  const showHighscore = async () => {
    const res = await axios.get(`/guest/highscore`);
    const result: any = res.data.data;
    console.log('score', result)
    if (!!result && result.length > 0) {
      setAllRank(result);
      setTimeout(() => navigate('/leaderboard'), 500);
    } else {
      toast.info('There are no players.')
    }
  }

  return (
    <>
      <div className="header pt-1 z-10">
        {menu === 0 ? (
          <span className={classNames("!pt-6 !pl-9 menu_btn ", isResultPage ? "text-cyan-600/75  !font-normal" : "")} onClick={onclick_menu}>
            Menu
          </span>
        ) : menu === 1 ? (
          flag === 0 ? (
            <span className="menu_btn !pt-6" onClick={onclick_home}>
              Home
            </span>
          ) : (
            <span className="menu_btn !pt-6" onClick={onclick_home_back}>
              Back
            </span>
          )
        ) : (
          <span className="menu_btn !pt-6" onClick={onclick_back}>
            Back
          </span>
        )}
        {isResultPage ? (
          <div className='!pt-6 !pr-9 flex text-cyan-600/75 cursor-pointer items-center !font-normal'
            onClick={() => window.open("https://discord.gg/noundles")}>
            DISCORD&nbsp;
            <img src={discordImg} alt="discord" className='w-6 h-6 object-contain' />
          </div>
        ) : isCollection ? (
          <div className="collection_btn" onClick={onclick_collection}>
            <span>Collection</span>
            <img src={collection} alt="opensea" width="25px" />
          </div>
        ) : menu === 0 ? (
          isAuth ? (
            <div className="collection_btn" onClick={onclick_collection}>
              <span>Collection</span>
              <img src={collection} alt="opensea" width="25px" />
            </div>
          ) : (
            <div className="collection_btn">
              <button onClick={onclick_signin}>sign in</button>
            </div>
          )
        ) : (
          <></>
        )
        }
        <div
          className={
            visible === 0
              ? "menu_content"
              : visible === 1
                ? "menu_content_open"
                : visible === -1
                  ? "menu_content_close"
                  : ""
          }
          style={
            screenHeight ? { height: screenHeight + "px" } : { height: "100vh" }
          }
        >
          <div className="pt-20">
            <span className="text-black pb-1 capitalize" style={{ fontFamily: 'SmallFont', fontSize: '1.7rem', fontWeight: '500' }}>{avator}</span>
            <img src={mooseImg} alt='moose' className='pt-4' style={{ width: '30%' }} />
            <hr className="mt-0 mb-1" />
          </div>
          <div className="menu-items">
            <span className="menu-icon" style={{ color: "#8dc63f" }}>
              A
            </span>
            <span className="menu-item" onClick={onclick_home}>
              home
            </span>
          </div>
          <div className="menu-items" onClick={onclick_collection}>
            <span className="menu-icon" style={{ color: "#39b54a" }}>
              B
            </span>
            <span className="menu-item">collection</span>
          </div>
          <div className="menu-items" onClick={() => showHighscore()}>
            <span className="menu-icon" style={{ color: "#00a99d" }}>
              C
            </span>
            <span className="menu-item">high scores</span>
          </div>
          <div className="is-auth">
            <span className="menu-icon" style={{ color: "#f26c4f" }}>
              {user ? "E" : "D"}
            </span>
            {user ? (
              <span className="menu-item" onClick={onclick_logout}>
                sign out
              </span>
            ) : (
              <span className="menu-item" onClick={onclick_login}>
                sign in
              </span>
            )}
          </div>
          <div className="menu-close" onClick={onClose}>
            <img src={menuIcon} alt="menu" width="15px"></img>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Header;
